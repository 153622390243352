import { NAMESPACE } from "../../../constants"
import { LitElement, css, html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { RelativeTime, ColorGenerator } from '../../../utils'
import { getLang } from "../../../utils/locale"
// import { when } from 'lit/directives/when.js';
import { msg, str } from '@lit/localize';

import styles from "./list.css"


const getAvatarColor = ColorGenerator({ lowerHue: 0, upperHue: 360 })
const relativeTime = RelativeTime({ from: new Date(), lang: getLang() })


function template(elem) {
    const { data, limit, offset = 0 } = elem

    const items = data.slice(offset, limit)

    function renderItem(item, index) {

        const { 
                comment, 
                reviewer_displayName, 
                reviewer_profilePhotoUrl ,
                createTime,
                urlReview
            } = item
        
        function renderLink(text) {
            return urlReview 
                ? html`<a href="${urlReview}" target="_blank">
                        ${text} 
                        <oportun-icon name="external-link"></oportun-icon>
                    </a>` 
                : source
        }

        function renderReviewInfo(item) {
            const { source, starRating } = item

            const stars = Array(5).fill(0).map((n, i) => i+1)

            return html`
                <div class="info">
                    <span class="stars">${
                        stars.map((n) => {
                        //#F8B70D
                            return html`<span 
                                class="${n <= +starRating ? 'on' : 'off'}">★</span>`
                        })
                    }</span>
                    <span class="from-src">${msg('from')} ${renderLink(source)}</span>
                    <span class="at">${relativeTime(createTime)}</span>
                </div>`
        }

        
        function avatar() {
            if (reviewer_profilePhotoUrl) {
                return html`<img width="120" height="120" 
                                src="${reviewer_profilePhotoUrl}" 
                                data-src="${reviewer_profilePhotoUrl}" 
                                alt="profile picture" 
                                data-ll-status="loaded"
                                loading="lazy" 
                                decoding="async">`
            }

            const initials = (reviewer_displayName || '').split(' ').slice(0,2)
                    .filter(Boolean).map(w => w.charAt(0))
                    .join('').toUpperCase();

            return html`<span class="avatar" 
                style="background:${getAvatarColor(reviewer_displayName)};">${initials}
            </span>`
        }

        return html`
            <div class="item">
                <div class="comment">
                    <p>${comment}</p>
                </div>
                <div class="author flex-wrapper">
                    <div class="details">
                        <div class="pic">
                            ${avatar(item)}                            
                        </div>
                        <div class="detail">
                            <p class="author-name">${reviewer_displayName}</p>
                            ${renderReviewInfo(item)}
                        </div>
                    </div>
                </div>
            </div>
        `
    }


    function renderMoreButton() {
        if (limit >= data.length) {
            return ``
        }

        return html`
            <div class="load-more-reviews">
                <button class="btn outline" 
                    @click=${elem.loadMore}
                    id="loadmorereview" 
                    data-event_tracking_label="load more">
                        ${msg("Load more")}
                </button>
            </div>`
    }

    return html`
        <div class="list">
                ${ repeat(items, (item) => item.reviewId, renderItem) }
        </div>
            ${renderMoreButton()}
    `;

}



export default class ReviewsList extends LitElement {
    static styles = styles

    static properties = {
        data: {},
        limit: 0,
        perPage: 6,
        offset: 0
    };


    constructor() {
        super();
        this.offset = 0
        this.perPage = 6 // 
        this.limit = 6 // default
    }

    loadMore(e) {
        this.limit += this.perPage
    }


    render() {
        return template(this)
    }

}

customElements.define(`${NAMESPACE}-reviews-list`, ReviewsList);
