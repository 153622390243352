import { NAMESPACE } from "../../../constants"
import { html } from 'lit';
import { msg, str } from '@lit/localize';

import Base from "./base.js"

function template(elem) {
    const { config, placeholder = " " } = elem
    const { name, label, type, span, required, error_message } = config
    const req = required === 'yes' ? true : false


    return html`
        <textarea 
            class="input"
            placeholder="${placeholder}" 
            @change=${elem.onChange} 
            @input=${elem.onInput}
            required 
        ></textarea>
        <div class="label">
            <label id="${name}_label" class="text">${label}${req?'*':''}</label>
            <span id="${name}_error" class="error_message">${error_message}</span>
        </div>
    `;

}

export default class TextArea extends Base {
    constructor() {
        super();
    }

    onInput(e) {
        const { target } = e
        target.style.height = '0px';
        target.style.height = `${target.scrollHeight}px`
    }

    render() {
        return template(this)
    }
}

customElements.define(`${NAMESPACE}-textarea`, TextArea);
