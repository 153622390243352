import { match } from './responsive';

export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function clamp(n, min, max) {
  return Math.min(Math.max(n, min), max);
}

/**
 *  Map a number from one range to another
 *
 * n = 20
 * [0..100] => [210...6000]
 *
 * @param {number} n the number to map
 * @param {number} a the low end on the inut number
 * @param {number} b the high end of the input number
 * @param {number} x the lower end of the output range
 * @param {number} y the higher end of the output range
 * @returns
 */
export function remap(n, a, b, x, y) {
  return x + ((n - a) * (y - x)) / (b - a);
}

export function delay(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function RelativeTime({ from = new Date(), lang = 'en' } = {}) {
  const UNITS = [
    { u: 'year', n: 24 * 60 * 60 * 1000 * 365 },
    { u: 'month', n: (24 * 60 * 60 * 1000 * 365) / 12 },
    { u: 'day', n: 24 * 60 * 60 * 1000 },
    { u: 'hour', n: 60 * 60 * 1000 },
    { u: 'minute', n: 60 * 1000 },
    { u: 'second', n: 1000 },
  ];

  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });

  return function format(date) {
    const d = new Date(date);
    const diff = d - from;

    for (const { u, n } of UNITS) {
      if (Math.abs(diff) > n) {
        return rtf.format(Math.round(diff / n), u);
      }
    }
  };
}

export function __getOS() {
  const uA = navigator.userAgent || navigator.vendor || window.opera;
  if (
    (/iPad|iPhone|iPod/.test(uA) && !window.MSStream)
    || (uA.includes('Mac') && 'ontouchend' in document)
  ) return 'iOS';

  console.log(uA);

  let i;
  const os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
  for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
}

export function getOS() {
  const { userAgent } = window.navigator;
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export function isTouch() {
  return window.matchMedia('(pointer: coarse)').matches;
}

export function isIOS() {
  const os = getOS();
  return os === 'iOS';
}

export function isAndroid() {
  const os = getOS();
  return os === 'Android';
}

export function isNarrow(fn) {
  return match('(min-width: 768px)', fn);
}

export function sleep(time = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function ColorGenerator({
  lowerHue = 0,
  upperHue = 360,
  lightness = 80,
  saturation = 60,
  opacity = 1,
} = {}) {
  const colors = {};

  function getColor() {
    const hue = random(lowerHue, upperHue);
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
  }

  return (key) => {
    let color;
    if (key) {
      color = colors[key] || getColor();
      colors[key] = color;
    }
    return color || getColor();
  };
}

export function RAF(ontick, fps) {
  let raf;

  const tick = (time) => {
    raf = window.requestAnimationFrame(tick);
    ontick(time);
  };

  raf = window.requestAnimationFrame(tick);

  const cancel = () => {
    window.cancelAnimationFrame(raf);
  };

  return {
    id: raf,
    cancel,
  };
}

export function debounce(fn, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, timeout);
  };
}
