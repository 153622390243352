import {
  NorthStarElement, css, html, define,
} from '../../northstar';

import spriteImage from '../../../assets/brand/hero.svg';

const styles = css`
  :host {
    display: inline-block;
    width: var(--sprite-width, 100%);
    height: var(--sprite-height, var(--sprite-width, 100%));
    box-sizing: border-box;
    padding: var(--icon-padding, 0);
    max-width: 1100px;
    margin: 0 auto;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: var(--icon-fill, none);
    stroke: var(--icon-stroke, black);
    stroke-width: var(--icon-stroke-width, 1);
  }
`;

class SVGSprite extends NorthStarElement {
  static styles = styles;

  static properties = {
    name: { type: String },
    src: { type: String },
    width: { type: Number },
    height: { type: Number },
  };

  constructor() {
    super();
    this.src = spriteImage;
    this.width = 600;
    this.height = 400;
  }

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
        <use href="${this.src}#${this.name}" />
      </svg>
      <slot></slot>
    `;
  }
}

define('sprite', SVGSprite);
