import { NorthStarElement, html, css, define } from '../../northstar';
import {range} from 'lit/directives/range.js';
import {map} from 'lit/directives/map.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

import { msg, str } from '@lit/localize';
import { setLocale, getLang } from '../../utils/locale';
import { urlStore } from "../../utils/url_store"

const formatter = new Intl.ListFormat(getLang(), { style: 'long', type: 'conjunction' });

import styles from './faq.css';

function template(elem){


    const { questions, categories, filters, selectedTab, totalFilters, totalQuestions,  selectedFilters, isMobile} = elem;

    function renderQuestions(questions, slug) {
        
        function renderQuestion(question, i) {

            return html`
            <oportun-accordion-item class="item stacked" type="stacked" mode="collapsible" collapsed="${selectedTab === 1 ? false : true}">
                <div class="title" slot="title">
                    ${question.title}
                </div>
                <div class="content">
                    ${unsafeHTML(question.content) }
                </div>
            </oportun-accordion-item>`
        }

        return html`
        <oportun-accordion>
            ${map(questions, renderQuestion)}
        </oportun-accordion>
        
        
        `;
    }
    // return html`${renderContent}`


    function isSelected(filter) {
        return selectedTab.find(f => f.slug === filter.slug)
    }

    const isGrouped = !Array.isArray(questions)

    console.log("isGrouped", isGrouped, questions)

    

    function renderSelection() {
        const selection = selectedTab.map(f => f.name)
        const formatted = formatter.formatToParts(selection) 

        return formatted.map(part => part.type === 'element' ? html`<span class='faq-filter' >${part.value}</span>`: part.value)
    }

    
    function renderGrouped() {
        return html`${Object.entries(questions).map(([slug, items]) => {
            
            const cat = categories.find(c => c.slug === slug)

            return html`
            <div class="group">  
                <h3>${cat.name}</h3> 
                <div class="questions"> 
                    ${renderQuestions(items, slug)}
                </div>
            </div>
            `
        })}  `
    }

    function renderUngrouped() {
        return html`
            <div class="questions"> 
                <div class="faq-active-filters">
                    <h3>Questions related to ${renderSelection(selectedTab)}</h3>
                </div>
                ${questions.length ? renderQuestions(questions) : html`
                    <strong>We couldn't find any questions that matched your selection.</strong>
                `}
            </div>
        `
    }
    function renderDesktopFilters(){
        return html `
            <div class="faq-filters selected-${selectedTab}"  >
            ${map(filters, (filter, i) => {
                return html`<div class="filter ${isSelected(filter) ? 'active' : 'inactive' }">
                        <span data-demo='' @click=${(e) => elem.handleSelect(filter, e)}>${filter.name}</span>
                    </div>`
            })}
            </div>
        `
    }
    function renderMobileFilters(){
        return html` 
        <oportun-accordion class='faq-filters-mobile'>
            <oportun-accordion-item icon='{"open":"arrow-down","close":"arrow-up"}' class="item stacked menu" type="stacked" mode="collapsible" collapsed="true">
                <div class="title" slot="title">
                    <strong>Categories</strong>
                </div>
                <div class="content">
                    
                <div class="faq-filters selected-${selectedTab}"  >
                ${map(filters, (filter, i) => {
                    return html`<div class="filter ${isSelected(filter) ? 'active' : 'inactive' }">
                            <span data-demo='' @click=${(e) => elem.handleSelect(filter, e)}>${filter.name}</span>
                        </div>`
                })}
                </div>
                    
                </div>
            </oportun-accordion-item>
        </oportun-accordion>    
        `
    }

    return html`
            ${ isMobile ? renderMobileFilters() : renderDesktopFilters()}
            <div class="questions-container" >
                ${isGrouped ? renderGrouped(): renderUngrouped()}    
            </div>
            `;
}


function groupBySlug(items) {
    return items.reduce((acc, item, index ) => {
        const slug = item['category-slug']

        if (!acc[slug]) acc[slug] = []
        acc[slug].push(item)

        return acc
    }, {})
}




export default class FrequentlyAskedQuestions extends NorthStarElement {
    static styles = styles;
    
    static template = template;
    
    static properties = {
        selectedTab: { type: Array },
        totalFilters: { type: Number },
        totalQuestions: { type: Number },
        isMobile: { type: Boolean },
        //categories: { type: Array },
        selectedFilters: { type: Array }
    }

    constructor() {
        super();
        this.selectedTab = [];
        this.filterfaq = new Array();
        this.currenturl = new URL(window.location.href);
        this.allQuestions = []
        this.categories = []
        this.filters = []

        this.url = urlStore(window.location.href)
    }

    get questions() {
        
       console.log(this.allQuestions)
        if (this.selectedTab.length === 1 && this.selectedTab[0].slug === 'all') {
            return groupBySlug(this.allQuestions)
        }

        let  items =  this.allQuestions.filter((q) => {

            const postCats = q.post_categories.split(',')
            const filteredArray = this.selectedTab.every(f => postCats.includes(f.slug));

            return filteredArray
        })

        items = items.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.title === value.title 
            ))
        )
      

        return items
    }

    async fetchData() {
        const { faqs } = window
        return faqs
    }

  
    async attachedCallback() {
        const response = await this.fetchData()
        const { questions, categories, filters } = response
        this.allQuestions = questions
        this.categories = categories
        this.filters = [{ name: msg('All'), slug: 'all'}, ...filters]
        console.log('categories' , this.categories)
        
        const faqFilterURL = this.url.get( 'filter' );
        if(faqFilterURL){

            const urlFiltering = faqFilterURL.split(',');
            urlFiltering.map((filter) => {

                const idx = this.categories.findIndex(f => f.slug === filter)
                if (idx > -1) {
                    this.selectedTab.push(this.categories[idx])
                }
            })

        }
        else{
            this.selectedTab[0] = this.filters[0]
        }

        this.url.subscribe((name, newValue, oldValue) => {
            window.history.pushState({ name: newValue }, "", this.url.url);
            this.searchTerm = newValue
        })

        const onpopstate = (event) => {
            this.url.updateUrl(window.location.href)
            
            this.initializeLocation()
        };

        window.addEventListener('popstate', onpopstate);

        this.requestUpdate() 
        
        const ro = new ResizeObserver(entries => {
            entries.forEach(entry => {
                if(entry.contentRect.width < 860){
                    this.isMobile = true;

                } else {
                    this.isMobile = false;
                    
                }
            })
        })

        ro.observe(document.body)
    }

    handleSelect(filter, event) {

        if (filter.slug === 'all') {
            this.selectedTab.length = 0
            this.selectedTab.push(filter)
            this.url.set( 'filter', null )

        } else {
            const idx = this.selectedTab.findIndex(f => f.slug === filter.slug)
            if (idx > -1) {
                this.selectedTab.splice(idx, 1)
            } else {
                this.selectedTab.push(filter)
            }

            const allIdx = this.selectedTab.findIndex(f => f.slug === 'all')
            allIdx > -1 && this.selectedTab.splice(allIdx, 1)
            let slugsURL = '';
            this.selectedTab.map(function (el) { 
                slugsURL+=el.slug+','; 
            });

            slugsURL = slugsURL.slice(0, -1);
            this.url.set('filter',slugsURL)
            
        }

        this.requestUpdate()

        console.log("this.selectedTab", this.selectedTab)

    }
}

define('faq', FrequentlyAskedQuestions);