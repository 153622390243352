import {
  NorthStarElement, html, svg, css, define,
} from '../../northstar';

const styles = css`
  :host {
    display: inline-block;
    width: var(--loader-width, 24px);
    height: var(--loader-height, var(--loader-width, 24px));
    box-sizing: border-box;
    padding: var(--loader-padding, 0);
  }
  svg {
    width: 100%;
    height: 100%;
  }
  line {
    stroke: var(--loader-stroke, var(--color-primary, black));
    stroke-width: var(--loader-stroke-width, 4);
    stroke-linecap: round;
    stroke-miterlimit: miter;
  }
  circle {
    fill: var(--loader-circle-fill-color, none);
    stroke: var(--loader-circle-stroke-color, none);
    stroke-width: var(--loader-circle-stroke-width, 0);
  }
`;

function template(elem) {
  const { lines } = elem;

  return html`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="10" />
      <g id="lines" transform-origin="50 50">
        ${lines.map(({
    x1, y1, x2, y2, tr, tx,
  }) => svg`
                            <line stroke-miterlimit="round" stroke-dasharray="100" stroke-dashoffset="0" pathLength="100" x1=${x1} y1=${y1} x2=${x2} y2=${y2} 
                                transform="rotate(${tr}, 50, 50) translate(${tx}, 0) "/>
                        `)}
      </g>
    </svg>
  `;
}

class LoadingSpinner extends NorthStarElement {
  #lines = null;

  static styles = styles;

  static template = template;

  static properties = {
    name: '',
    count: 20,
    x: 50,
    y: 50,
    innerRadius: 20,
    outerRadius: 45,
  };

  get lines() {
    if (this.#lines) {
      return this.#lines;
    }
    const {
      count = 20, innerRadius = 20, outerRadius = 45, cX = 50, cY = 50,
    } = this;

    /*
            let x1 = cX + innerRadius * Math.cos((2 * Math.PI) * i / count)
            let y1 = cY + innerRadius * Math.sin((2 * Math.PI) * i / count)
            let x2 = cX + outerRadius * Math.cos((2 * Math.PI) * i / count)
            let y2 = cY + outerRadius * Math.sin((2 * Math.PI) * i / count)
        */

    this.#lines = Array(count)
      .fill(null)
      .map((_, i) => {
        const x1 = cX + innerRadius;
        const y1 = cY + 0;
        const x2 = cX + outerRadius;
        const y2 = cY + 0;

        const tr = (i / count) * 360;
        const tx = 0;

        return {
          x1,
          y1,
          x2,
          y2,
          tr,
          tx,
        };
      });
    return this.#lines;
  }

  attachedCallback() {
    const g = this.shadowRoot.querySelector('g');
    const lines = this.shadowRoot.querySelectorAll('line');

    this.anims = [...lines].map((line, i) => {
      const anim = line.animate(
        [
          { opacity: 0, strokeDashoffset: 10, offset: 0 },
          { opacity: 1, strokeDashoffset: 0, offset: 1 },
        ],
        {
          duration: 800,
          delay: (i / lines.length) * 800,
          iterations: Infinity,
          easing: 'ease-in-out',
          fill: 'both',
        },
      );

      return anim;
    });
    this.anims.push(
      g.animate([{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }], {
        duration: 16000,
        iterations: Infinity,
        fill: 'both',
      }),
    );

    return () => {
      this.anims.forEach((a) => a.cancel());
    };
  }
}

define('loading-spinner', LoadingSpinner);
