import { NAMESPACE } from "../../../constants"
import { html } from 'lit';
import { msg, str } from '@lit/localize';

import Base from "./base.js"

export default class Input extends Base {
    constructor() {
        super();
    }
}

customElements.define(`${NAMESPACE}-input`, Input);
