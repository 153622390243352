import { NAMESPACE } from "../../../constants"
import { LitElement, html } from 'lit';
import { msg, str } from '@lit/localize';

import styles from "./button.css"

function template(elem) {
    const { config, text = "Submit", type = "submit" } = elem
    const { name, label, span, required } = config
    const req = required === 'yes' ? true : false

    return html`
        <button 
            class="btn outline" 
            type="${type}"
            @click=${elem.onChange}
        >${text}</button>
    `;

}

export default class Button extends LitElement {

    static styles = styles

    static properties = {
        config: {},
        name: "",
        text: "Submit",
        type: "button",
    };

    constructor() {
        super();
    }

    render() {
        return template(this)
    }
}

customElements.define(`${NAMESPACE}-button`, Button);
