import { KeyCodes } from '../../utils/aria';

import { NorthStarElement, html, define } from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';

import styles from './heroslider.css';

function template(elem) {
  const { show, count = 0, selected = 1, isMobile } = elem;

  const slides = Array(+count)
    .fill(null)
    .map((_, i) => i);

  function renderSlide() {
    return html`<div class="slide">
      <slot name="slide-${selected}"></slot>
    </div>`;
  }
  function renderDesktop(){
    return html`
    <div class="slider">
      <div class="slides">${selected ? renderSlide() : ''}</div>
      <div class="cards">
        ${slides.map(
        (slide, index) => html`<div
            class="card-container ${+selected === index + 1 ? 'selected' : ''}"
            data-index="${index + 1}"
            @pointerenter="${elem.onCardHover}"
            @click="${(index ) => elem.handleClickWholeCard(index, elem)}"
          >
            <slot name="card-${index + 1}" class="${+selected === index + 1 ? 'selected-card' : ''}"></slot>
          </div>`,
        )}
      </div>
    </div>
  `;
  }
  function renderMobile(){
    return html`
      <div class="cards-mobile">
      ${slides.map(
      (slide, index) => html`
        <div
          class="card-container ${+selected === index + 1 ? 'selected' : ''}"
          data-index="${index + 1}"
          @pointerenter=${elem.onCardHover}
          
        >
          <slot name="card-${index + 1}"></slot>
        </div>`,
        
      )}
      </div>
    `;
  }
  return html`
            ${ isMobile ? renderMobile() : renderDesktop()}
          `;
}

export default class HeroSlider extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    count: { type: 'number' },
    show: { type: 'boolean' },
    selected: { type: 'number' },
    isMobile: { type: Boolean },
  };

  getSlottedChildren(selector) {
    const slot = this.shadowRoot.querySelector(`slot[name="${selector}"]`);
    return slot.assignedElements({ flatten: true });
  }

  onSlotChange(e) {
    const attachEvents = (card, idx) => {
      card.addEventListener('pointerenter', () => {
        this.selected = idx + 1;
      });
    };
    const cards = this.getSlottedChildren('cards');
    console.log('cards', cards);
    cards.forEach(attachEvents);
  }

  onCardHover(e) {
    const { target } = e;
    this.selected = +target.dataset.index;

    const event = new CustomEvent('custom-event', {
      bubbles: true,
      detail: {
        action: 'hover',
        description: 'Hero Slider: Hovering a card',
      },
    });

    this.dispatchEvent(event);
  }

  onSlideSlotChange(e) {}

  async attachedCallback() {
    const ro = new ResizeObserver(entries => {
      entries.forEach(entry => {
          if(entry.contentRect.width < 860){
              this.isMobile = true;

          } else {
              this.isMobile = false;
              
          }
      })
    })

    ro.observe(document.body)
  }

  handleClickWholeCard(e, item){
    
    const clickedCard = item.querySelector('a');
    if (! this.isObjectEmpty(clickedCard)) {
      window.location = clickedCard.href;
      console.log(clickedCard.href);
    }
  }
  
  isObjectEmpty(objectName){
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  }
}

define('hero-slider', HeroSlider);
