import { KeyCodes } from '../../../utils/aria';

import { NorthStarElement, html, define } from '../../../northstar';
import { setLocale, getLang } from '../../../utils/locale';

import styles from './generic.css';

function template(elem) {
  return html`
    <div class="basic">
      <div class="wrapper">
        <div class="content">
          <slot name="title"></slot>
          <slot name="description"></slot>
          <slot></slot>
        </div>
      </div>
    </div>
  `;
}

export default class GenericBlock extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {};

  async attachedCallback() {}
}

define('generic', GenericBlock);
