import { NAMESPACE } from "../../constants"
import { LitElement, css, html } from 'lit';
import { KeyCodes } from "../../utils/aria"

import styles from "./main-nav.css"

export default class MainNav extends LitElement {
    static styles = styles

    static properties = {
        limit: 6
    };

    constructor() {
        super()
        this.closeMenu = (e) => {
            if (this.activeMenu) {
                this.activeMenu.setAttribute('aria-expanded', "false");
                const ul = this.activeMenu.parentNode.children[1]
                ul.setAttribute("aria-hidden", true)
                ul.setAttribute("aria-activedescendant", "")
            }

            if (this.activeElem) {
                this.activeElem.classList.remove('is-selected')
            }

            this.activeElem = null
            this.activeMenu = null
            document.removeEventListener('click', this.closeMenu)
        }
    }

    get _slottedChildren() {
        const slot = this.shadowRoot.querySelector('slot');
        return slot.assignedElements({ flatten: true });
    }

    handleSlotchange() {
        const nav = this._slottedChildren[0]
        const ul = nav.children[0];

        nav.setAttribute('tabindex', -1);
        //aria-activedescendant="option-elem-${activeIndex}"

        ul.setAttribute('aria-activedescendant', '');
        ul.setAttribute('aria-hidden', 'true');
        ul.setAttribute('role', 'menu');
        
        [...ul.children].forEach((li, i) => {
            const a = li.children[0]
            li.setAttribute("role", "presentation")
            a.setAttribute('tabindex', i+4)
            a.setAttribute('role', "button")
            a.setAttribute('aria-pressed', "false")

            const menu = li.children[1]
            if (menu) {
                a.addEventListener('keydown', this.handleMainMenuKeydown.bind(this))
                a.setAttribute('aria-expanded', "false");
                for (let item of menu.children) {
                    item.setAttribute('role', 'presentation')
                    item.children[0].setAttribute('role', 'menuitem')
                }
            } else {
                a.removeAttribute('aria-expanded');
            }
        })
    }


    

    handleMainMenuKeydown(e) {

        const key = e.which || e.keyCode;
        
        const { target } = e

        const li = target.parentNode
        const ul = li.children[1]

        if (!ul) {
            // when tabbing out of current menu
            return
        }

        const len = ul.children.length

        let currentState = target.getAttribute('aria-expanded')

        const cancelEvent = (evt = e) => {
            evt.preventDefault()
            evt.stopPropagation()
        }

        const toggleSubMenu = (state, cancel = true) => {
            cancel && cancelEvent()

            if (target.getAttribute("aria-haspopup") !== "true") {
                return
            }
            if (typeof(state) === 'string') {
                currentState = state
            } else {
                currentState = currentState === "true" ? "false" : "true"
            }

            ul.setAttribute("aria-hidden", currentState)
            target.setAttribute('aria-expanded', currentState);

            // reset all focused inner elements when toggling menu
            ul.setAttribute("aria-activedescendant", "")
            if (this.activeElem) {                
                this.activeElem.classList.remove('is-selected')
            }

            this.activeElem = null

            if (currentState === 'true') {
                this.activeMenu = target
                document.addEventListener('click', this.closeMenu)
            } else {
                this.activeMenu = null
                document.removeEventListener('click', this.closeMenu)
            }
            
        }

        const setFocus = () => {
            cancelEvent()

            const activeId = ul.getAttribute("aria-activedescendant")
            let activeElem = activeId && this.querySelector(`#${activeId}`)
            
            if (key === KeyCodes.UP) {
                if (!activeElem) {
                    activeElem = ul.children[len-1]
                } else {
                    activeElem = activeElem.previousElementSibling || ul.children[len-1]
                }
            }
            if (key === KeyCodes.DOWN) {
                if (!activeElem) {
                    activeElem = ul.children[0]
                } else {
                    activeElem = activeElem.nextElementSibling || ul.children[0]
                }
            }
            
            ul.setAttribute("aria-activedescendant", activeElem.getAttribute('id'));
            // setting focus to item will remove focus from main nav item
            // tabbing through will not work afterwards
            //activeElem.firstElementChild.focus()
            
            for (let item of ul.children) {
                item.classList.remove('is-selected')
            }
            activeElem.classList.add('is-selected')

            this.activeElem = activeElem
        }

        const navigateTo = () => {
            
            if (this.activeElem) {
                cancelEvent()
                this.activeElem.children[0].click()
            }
            toggleSubMenu()
        }

        switch (key) {
            case KeyCodes.UP:
            case KeyCodes.DOWN:
                cancelEvent()
                setFocus(key)
              break;
            case KeyCodes.RETURN:
                navigateTo()
            break;
            case KeyCodes.SPACE:
                toggleSubMenu()
            break;
            case KeyCodes.ESC:
            case KeyCodes.TAB:
                toggleSubMenu("false", false)
            break;
          }

    }


    render() {
        return html`
            <slot name="nav" @slotchange=${this.handleSlotchange}></slot>
        `
    }
}


customElements.define(`${NAMESPACE}-main-nav`, MainNav);