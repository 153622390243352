import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';

import styles from './item.css';

import './item';

function template(elem) {
  const { type, link } = elem;

  function renderRegular() {
    if (link) {
      return html`
        <a class="item" href="${link}">
          <slot name="image"></slot>
          <slot></slot>
        </a>
      `;
    }
    return html`
      <div class="item">
        <slot name="image"></slot>
        <slot></slot>
      </div>
    `;
  }

  function renderWholeClick() {
    return html`
      <div class="item" @click=${(e) => elem.handleClickWholeCard(e, elem)}>
        <slot name="image"></slot>
        <slot></slot>
      </div>
    `;
  }

  if (type == 'card') {
    return html`${renderWholeClick()}`;
  }
  return html`${renderRegular()}`;
}

export default class StaticListItem extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    type: { type: String },
    hasAnchortag: { type: Boolean },
    link: { type: String },
  };

  constructor() {
    super();
  }

  async attachedCallback() {}

  handleClickWholeCard(e, item) {
    const clickedCard = item.querySelector('a');
    if (!this.isObjectEmpty(clickedCard)) {
      window.location = clickedCard.href;
      console.log(clickedCard.href);
    }
  }

  isObjectEmpty(objectName) {
    return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
  }
}

define('list-item', StaticListItem);
