import { get, set } from 'idb-keyval';
import { KeyCodes } from '../../utils/aria';
import { NorthStarElement, html, define } from '../../northstar';
import { RAF } from '../../utils';

import styles from './header.css';

function template(elem) {
  const {
    allowbannerhide = true, top, banner, mobileHeader, state, scrolled,
  } = elem;

  return html`
    <div
      class="wrapper ${state}
        ${scrolled ? 'scrolled' : ''}
        ${mobileHeader ? 'mobile' : ''} ${banner === 'true' ? 'has-banner' : ''} ${top
  ? 'top'
  : ''}"
    >
      ${banner === 'true'
    ? html`<div class="banner">
            <slot name="banner"></slot>
            ${allowbannerhide
    ? html`<span
                  class="close"
                  @click="${elem.closeBanner}"
                  data-event_tracking="link click"
                  data-event_tracking_label="Close banner"
                  data-event_tracking_section="Header"
                >
                  <oportun-icon name="close"></oportun-icon>
                </span>`
    : ''}
          </div>`
    : ''}
      <div class="nav"><slot name="nav"></slot></div>
    </div>
  `;
}

export default class Header extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    state: { type: String },
    scrolled: { type: Boolean },
    mobileHeader: { type: Boolean },
    banner: { type: String },
    allowbannerhide: { type: Boolean },
    top: { type: Boolean },
  };

  constructor() {
    super();
    this.progress = 0;
    this.position = {
      x: 0,
      y: 0,
    };
    this.allowbannerhide = true;

    if (window.innerWidth <= 1070) {
      this.mobileHeader = true;
    } else {
      this.mobileHeader = false;
    }
  }

  closeBanner(evt) {
    this.banner = false;
    this.removeAttribute('banner');
    set('prefs-hide-banner', JSON.stringify({ show: this.banner, time: Date.now() }));
  }

  async attachedCallback() {
    if (this.allowbannerhide) {
      get('prefs-hide-banner').then((val) => {
        if (val !== undefined) {
          try {
            const data = JSON.parse(val);
            const oneDay = 1 * 60 * 60 * 1000;
            if (data.time && Date.now() - data.time < oneDay) {
              this.banner = data.show;
            }
          } catch (e) {}
        }
      });
    }

    const raf = new RAF((time) => {
      if (this.progress > 0) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    });

    const handleScroll = (e) => {
      const { scrollHeight, clientHeight, scrollTop } = document.documentElement;
      const progress = (scrollTop / (scrollHeight - clientHeight)) * 100;

      const dir = scrollTop > this.position.y ? 1 : 0;

      this.position.y = Math.abs(scrollTop);
      this.progress = progress;

      if (progress < 1) {
        this.top = true;
      } else {
        this.top = false;
      }

      if (dir === 1) {
        this.state = 'hidden';
      } else {
        this.state = 'show';
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    const ro = this.resizeObserver(({ width }) => {
      if (window.innerWidth <= 1070) {
        this.mobileHeader = true;
      } else {
        this.mobileHeader = false;
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true });
      raf.cancel();
      ro.cancel();
    };
  }
}

define('header', Header);
