import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';

import styles from './list.css';

import './item';

function template(elem) {
  const { src } = elem;
  return html`
    <div class="list">
      <slot @slotchange=${elem.handleSlotchange}></slot>
    </div>
  `;
}

export default class StaticList extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    src: { type: String },
  };

  constructor() {
    super();
  }

  handleSlotchange() {}

  async attachedCallback() {}
}

define('list', StaticList);
