import { LitElement, css, html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { msg, str } from '@lit/localize';
import { NAMESPACE } from '../../constants';
// import { choose } from 'lit/directives/choose.js';

import { observer as resizeObserver } from '../../utils/resize';
import { setLocale, getLang } from '../../utils/locale';
import { remap, clamp } from '../../utils';

import styles from './slider.css';

function nearest(n, array) {
  return array.reduce((prev, num) => (Math.abs(num - n) <= Math.abs(prev - n) ? num : prev));
}
function atPercent(percent, array) {
  const len = array.length - 1;
  const idx = Math.round((percent / 100) * len);
  return array[idx];
}

function template(elem) {
  const {
    min, max, value, datalist, type = 'step', snap = false,
  } = elem;
  const { dragging } = elem;

  let left = 0;
  let points = [];
  if (type === 'step') {
    const steps = datalist.length - 1;
    const idx = datalist.indexOf(nearest(value, datalist));
    left = (idx / steps) * 100;
    points = datalist.map((n, i) => (i / steps) * 100);
  } else if (snap) {
    left = remap(+value, min, max, 0, 100);
    points = datalist.map((n) => remap(+n, min, max, 0, 100));
  } else {
    left = remap(+value, min, max, 0, 100);
  }

  return html`
    <div class="slider ${type}">
      <div class="knob ${dragging ? 'dragging' : ''}" style=${styleMap({ left: `${left}%` })}></div>
      <div class="markers">
        ${points.map(
    (point) => html`<div class="stop" style="${styleMap({ left: `${point}%` })}"></div>`,
  )}
      </div>
      <div class="progress" style="${styleMap({ width: `${left}%` })}"></div>
      <div class="track"></div>
    </div>
  `;
}

export default class AmountSlider extends LitElement {
  static styles = styles;

  static properties = {
    min: { type: 'number' },
    max: { type: 'number' },
    value: { type: 'number' },
    datalist: { type: 'datalist' },
    type: { type: 'string' },
  };

  setValueFromPercent(percent) {
    const {
      datalist, min, max, type = 'step', snap = false,
    } = this;

    let value;
    if (type === 'step') {
      value = atPercent(percent, datalist);
    } else if (snap) {
      const val = remap(percent, 0, 100, min, max);
      value = nearest(val, datalist);
    } else {
      value = remap(percent, 0, 100, min, max);
    }
    this.setValue(value);
  }

  setValue(value) {
    const event = new CustomEvent('change', {
      detail: { value },
      bubbles: true,
      cancelable: true,
    });
    this.dispatchEvent(event);
    if (!event.defaultPrevented) {
      this.value = value;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    let startX;
    let bounds;

    const drag = (e) => {
      // e.preventDefault();

      this.dragging = e.type === 'pointermove';

      const x = e.pageX - startX;
      const layerX = clamp(x - bounds.left + startX, 0, bounds.width);
      const val = (layerX / bounds.width) * 100;

      this.setValueFromPercent(val);
      this.requestUpdate();
    };

    const dragEnd = (e) => {
      // e.preventDefault();

      this.dragging = false;
      this.requestUpdate();
      document.removeEventListener('pointermove', drag, { capture: true });
      document.removeEventListener('pointerup', dragEnd, { capture: true });
    };

    const dragStart = (e) => {
      e.preventDefault();

      startX = e.layerX;
      bounds = this.getBoundingClientRect();
      drag(e);

      document.addEventListener('pointermove', drag, { capture: true });
      document.addEventListener('pointerup', dragEnd, { capture: true });
    };

    this.addEventListener('pointerdown', dragStart, { capture: true });
  }

  render() {
    return template(this);
  }
}

customElements.define(`${NAMESPACE}-loan-slider`, AmountSlider);
