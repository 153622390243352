import {
  NorthStarElement, css, html, define,
} from '../../northstar';

import { Icon } from '../icon';

import Graphics from '../../../assets/brand/graphics.svg';

const styles = css`
  :host {
    display: inline-block;
    width: var(--graphic-width, 100px);
    height: var(--graphic-height, var(--graphic-width, 100px));
    box-sizing: border-box;
    padding: var(--icon-padding, 0);
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

class Graphic extends Icon {
  static styles = styles;

  constructor() {
    super();
    this.src = Graphics;
    this.viewBox = '0 0 100 100';
    this.width = '100';
  }

  attachedCallback() {
    if (this.width) {
      this.style.setProperty('--graphic-width', this.width);
    }
  }
}

define('graphic', Graphic);
