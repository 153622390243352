import { LitElement, css, html, svg } from 'lit';
import { NAMESPACE } from "../constants"

function createContext() {
    return {

    }
}

class ContextProvider extends LitElement {
    _context = {}

    constructor() {
        super()
        this._context = createContext(this)
        console.log("context created", this.context)
    }
    createRenderRoot() { return this; }
}

customElements.define(`${NAMESPACE}-context`, ContextProvider);