import { KeyCodes } from '../../../utils/aria';

import { NorthStarElement, html, define } from '../../../northstar';
import { setLocale, getLang } from '../../../utils/locale';
import { isNarrow } from '../../../utils';

import styles from './nav-menus.css';

function template(elem) {
  const { isMobile } = elem;

  function renderMobile() {
    return html`
      <oportun-accordion>
        <slot @slotchange=${elem.handleSlotchange}></slot>
      </oportun-accordion>
    `;
  }

  function renderDesktop() {
    return html`
      <oportun-grid>
        <slot @slotchange=${elem.handleSlotchange}></slot>
      </oportun-grid>
    `;
  }

  return html` <div class="container">${isMobile ? renderMobile() : renderDesktop()}</div> `;
}

export default class NavMenus extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    isMobile: { type: Boolean },
    type: { type: String },
  };

  handleSlotchange() {
    const children = this.getSlottedChildren();

    for (const child of children) {
      if (this.isMobile) {
        child.collapsible = true;
      } else {
        child.collapsible = false;
      }
    }
  }

  async attachedCallback() {
    const ro = this.resizeObserver(({ width }) => {
      if (window.innerWidth <= 1070) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    return () => {
      ro.cancel();
    };
  }
}

define('nav-menus', NavMenus);
