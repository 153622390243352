export function observer(node, callback) {
    
    const ro = new ResizeObserver(entries => {
        for (let entry of entries) {
          const bounds = entry.contentRect;
          callback({bounds, entry})
        }
    });
      // Observe one or multiple elements
    ro.observe(node);

    return {
        destroy() {
            ro.unobserve(node)
        }
    }

}