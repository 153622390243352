import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';

import styles from './accordion.css';

import './item';

function template(elem) {
  const { src } = elem;
  return html`
    <div class="items" @toggle=${elem.childToggle}>
      <slot></slot>
    </div>
  `;
}

export default class Accordion extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    src: { type: String },
  };

  constructor() {
    super();
  }

  childToggle(evt) {
    evt.stopPropagation();
    const children = this.getSlottedChildren();

    const { target, detail } = evt;
    for (const child of children) {
      if (!detail.collapsed) {
        if (child !== target) {
          child.collapsed = true;
        }
      }
    }
  }

  handleSlotchange() {}

  async attachedCallback() {}
}

define('accordion', Accordion);
