export function urlStore(href) {
    let url = new URL(href)
    let params = url.searchParams
    
    const subscriptions = []

    const store = {

        updateUrl(_href) {
            url = new URL(_href)
            params = url.searchParams
        },
        get url() {
            return url
        }, 
        get params() {
            return params
        },
        get(name) {
            return this.params.get(name)
        },
        setMultiple(props) {
            return Object.entries(props).reduce((acc, [key, val]) => {
                acc[key] = params.get(key)
                this.params.set(key, val)  
                return acc                  
            }, {})
        },
        set(name, value) {
            let oldValue
            if (value === null) {
                oldValue = params.get(name)
                params.delete(name)
            } else if (typeof name === 'object') {
                oldValue = this.setMultiple(name)
            } else {
                oldValue = params.get(name)
                this.params.set(name, value)
            }
            
            subscriptions.forEach((f) => f(typeof name === 'string' ? name : null, value, oldValue))
        },
        toJSON() {
            const data = {}
            // Iterating the search parameters
            for (const [key, value] of this.params) {
                data[key] = value
            }
            return data
        },
        subscribe(fn) {
            subscriptions.push(fn)
        }
    }

    return store
}