import { KeyCodes } from "../../utils/aria"

import { NorthStarElement, html, define } from "../../northstar"
import { setLocale, getLang } from "../../utils/locale"

import styles from "./grid.css"


function template(elem) {

    return html`
        <div class="wrapper">
            <div class="grid">
                <slot></slot>
            </div>
        </div>
        
    `
}


export default class Grid extends NorthStarElement {
    static styles = styles

    static template = template

    static properties = {
    };

    async attachedCallback() {
    }

}


define(`grid`, Grid);