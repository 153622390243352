import { KeyCodes } from '../../../utils/aria';

import { NorthStarElement, html, define } from '../../../northstar';
import { RAF, isTouch, getOS } from '../../../utils';

import styles from './cta-link.css';

function template(elem) {
  const {
    label,
    type = 'link',
    title,
    desc,
    width = type === 'footnote' ? 30 : 138,
    height = type === 'footnote' ? 20 : 25,
  } = elem;

  return html`
    <span class="${type || 'link'}">
      <svg
        width="${width }"
        height="${height }"
        viewBox="0 0 ${width } ${height}"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        ${title ? html`<title>${title}</title>` : ''} ${desc ? html`<desc>${desc}</desc>` : ''}
        <text x="${(width / 2)}" y="${height / 1.5}" text-anchor="middle" class="label">
          ${label}
        </text>
      </svg>
    </span>
  `;
}

export default class CTAText extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    label: { type: String },
    type: { type: String },
    desc: { type: String },
    title: { type: String },
    width: { type: Number },
    height: { type: Number },
  };

  constructor() {
    super();
  }

  handleClick(e) {}

  async attachedCallback() {
    const textElement = this.$('text');

    let baseLength = Math.floor(textElement.textLength.baseVal.value);

    if (baseLength === 0) {
      // if the element is not onscreen
      baseLength = this.label.length * 12;
    }

    this.width = baseLength;
  }
}

define('cta-text', CTAText);
