import { NAMESPACE } from "../../../constants"
import { LitElement, css, html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { getLang } from "../../../utils/locale"
// import { when } from 'lit/directives/when.js';
import { msg, str } from '@lit/localize';

import styles from "./input.css"

function template(elem) {
    const { config, pattern, placeholder = " " } = elem
    const { name, label, type, span, required, error_message } = config
    const req = required === 'yes' ? true : false

    const inputType = type === 'phone' ? 'tel' : type


    return html`
        <input 
            class="input"
            placeholder="${placeholder}" 
            type="${inputType}" 
            @change=${elem.onChange}
            ?required=${required}
            pattern="${ifDefined(pattern || undefined)}" 
            aria-labelledby="${name}_label"
        />
        <div class="label">
            <label id="${name}_label" class="text">${label}${req?'*':''}</label>
            <span id="${name}_error" class="error_message">${error_message}</span>
        </div>
    `;

}


export default class BaseInput extends LitElement {

    static styles = styles

    static properties = {
        config: {},
        pattern: "",
        placeholder: " ",
        name: ""
    };


    constructor() {
        super();
    }

    get field() {
        const field = this.shadowRoot.querySelector('.input')
        return field
    }

    get value() {
        return this.field.value
    }    

    get errorMessage() {
        return this.config.error_message || 'Invalid Input'
    }

    get validity() {
        return this.field.validity
    }

    checkValidity() {
        return this.field.reportValidity()
    }

    isValid() {
        return this.validity.valid
    }

    onChange(e) {
        const { target } = e

        if (target.validity.typeMismatch) {
            target.setCustomValidity(this.errorMessage);
            target.reportValidity();
        } else {
            target.setCustomValidity("");
        }
        
        if (target.value) {
            this.classList.add('filled')
        } else {
            this.classList.remove('filled')
        }

    }

    render() {
        return template(this)
    }

}
