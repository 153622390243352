import { NAMESPACE } from "../../../constants"
import { LitElement, css, html } from 'lit';
import { msg, str } from '@lit/localize';

import "../field"
import styles from './filters.css'


function starOption(stars, elem) {
    const starhtml = (n) => html`
        <strong style="color: var(${n <= stars  ? '--color-star-active' : '--color-star' });pointer-events: none;">★</strong>
    `
  return  html`${stars} ${[1,2,3,4,5].map(starhtml)} ${msg('& up')}`
}

function template(elem) {

    const { fields, store, mode } = elem

    function renderField([name, props], index) {

        const { label, items } = props

        const selected = store.get(name) || (items[0] && items[0].value) // ? use optional chaining

        const optionTemplate = (option, index) => {
            const { label, value } = option
            
            return html`                
                <oportun-icon part="icon" .name=${mode === 'list' ? 'circle' : 'checkmark'}></oportun-icon>
                <span part="text" class="option-text">${typeof label === 'function' ? label() : label}</span>
            `
            
        }

        const onFocus = (e) => {
            console.log("focused", e)
        }

        const config = {
            optionTemplate,
            onSelect(name, option, index) {
                elem.optionClicked(name, option)
            },
            isSelected(option, index) {
                const sel = store.get(name) || (items[0] && items[0].value)
                return option.value === sel
            },
            styles() {
                return css`
                    .option {
                        --icon-stroke: rgb(100, 100, 100, 0);
                        --icon-stroke-width: 2;
                    }
                    .option[aria-selected="true"] {
                        --icon-stroke: var(--color-primary);
                        color: var(--color-primary);
                    }
                    .list .option.selected,
                    .option.selected {
                        color: var(--color-primary);
                        --icon-stroke: var(--color-primary);
                        --color-star-active: var(--color-primary);
                    }

                    .list .option.selected .option-text:before {
                        content: '';
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: var(--color-primary);
                        position: absolute;
                        left: -27px;
                        top: 7px;
                        border-radius: 25px;
                    }

                    .list .option {
                        --icon-stroke: rgb(100, 100, 100, 1);
                        padding: 8px 4px;
                    }
                    
                `
            }
        }

        return html`
            <oportun-filter-field
                tabIndex="${index}"
                name=${name} 
                .label=${label} 
                .items=${items}
                .align=${name === 'sort_by' ? 'right' : 'left'} 
                .delay=${200}
                .duration=${300}
                .open=${mode === 'menu' ? false : true}
                .mode=${mode}
                .config=${config}
                .optionTemplate=${optionTemplate}
                @change=${elem.fieldChanged}
                @focus=${onFocus}
            >        
                ${ items.map(optionTemplate)}
            </oportun-filter-field>
        `

    }


    return html`<div class="fields ${mode}">
            ${Object.entries(fields).map(renderField)}    
        </div>`;
}






class Filters extends LitElement {

    static styles = styles

    static properties = {
        fields: {},
        store: {},
        mode: 'menu'
    };
      // Define scoped styles right with your component, in plain CSS
    
      constructor() {
        super();

        this.fields = {
            "sort_by": {
                label: msg("Sort by"),
                items: [
                    { value:"recent", label: msg("Most recent")},
                    { value:"highest", label: msg("Highest ratings")},
                    { value:"lowest", label:msg("Lowest ratings")},
                ]
            },
            "source" : {
                "label" : msg("See reviews from"),
                "items" : [
                    { value:"all", label: msg("All")},
                    { value:"google", label: "Google"},
                    { value:"trustpilot", label: "Trustpilot"},
                ]
            },
            "filter_by" : {
                "label" : msg("Filter by"),             
                "items" : [
                    { value:"all", label: msg("All") },
                    { value: '+4', label: () => starOption(4, this) },
                    { value: '+3', label: () => starOption(3, this) },
                    { value: '+2', label: () => starOption(2, this) },
                    { value: '+1', label: () => starOption(1, this) }
                ]
            }
        }

      }

      fieldChanged(e) {
        e.stopPropagation()
      }

      optionClicked(name, option) {
        this.store.set(name, option.value)
      }

      /**
       * triggerEvent
       * Triggers a custom event on this node
       * @param {string} name of the event. eg: change 
       * @param {object} options for the event, Use detail prop for event.detail 
       */
       triggerEvent(name, options = {}) {
        const event = new CustomEvent(name, { bubbles: true, composed: true, ...options });
        this.dispatchEvent(event);
      }

      /**
       * When connected, subscribe to the store for filter changes
       */
      connectedCallback() {
        super.connectedCallback()
        this.store.subscribe(() => this.requestUpdate())
      }
    
      render() {
        return template(this)
      }
}

customElements.define(`${NAMESPACE}-review-filters`, Filters);