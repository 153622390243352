import { KeyCodes } from "../../utils/aria"

import { NorthStarElement, html, define } from "../../northstar"
import { setLocale, getLang } from "../../utils/locale"

import styles from "./locations.css"

import '../loader'

function template(elem) {

    const { states } = elem

    function renderLoading() {
        return html`<div class="loading-spinner">
            <oportun-loading-spinner style="--loader-width:64px;"></oportun-loading-spinner>
        </div>` 
    }

    function renderLocations() {
        return html`
            <div class="wrapper">
                <oportun-locations-root .states=${states}></oportun-locations-root> 
            </div>` 
    }

    return html`
        <div class="locations">
            ${elem.loaded ? renderLocations() : renderLoading() }
        </div>
    `
}




export default class Locations extends NorthStarElement {
    static styles = styles

    static template = template

    static properties = {
        limit: 6,
        loaded: false
    };

    async attachedCallback() {
        // fetch compponent
        // and locations data

        //const module = await import("./root")

        // http://oportun.local/wp-json/wp/v2/states

        //const states = await fetch('http://oportun.local/wp-json/wp/v2/states')
        //const locations = await fetch('http://oportun.local/wp-json/wp/v2/locations')

        
        function getEndPointUrl(path, params = {}) {
            const lang = getLang()
            const lng = lang.split('-')[0]

            const url = new URL(`/wp-json/wp/v2/${path}`, window.location.origin);
            Object.entries(params).forEach(([k, v]) => {
                url.searchParams.set(k, v)
            })            
            if (lng !== 'en') {
                url.searchParams.set('lang', lng)
            }
            return url
        }

        function loadModule() {
            return import("./root")
        }

        function loadStates() {
            return fetch(getEndPointUrl('states', { per_page: 60 }))
                .then(response => response.json())
        }


        /*
        function loadLocations() {
            return fetch('http://oportun.local/wp-json/wp/v2/locations')
                .then(response => response.json())
        }
        */
        // loadLocations(), 

        const [module, states ] = await Promise.all([loadModule(), loadStates()])

        //console.log(states, locations, module)

        this.states = states
        this.loaded = true        

    }

}


define(`locations`, Locations);