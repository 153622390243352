import {configureLocalization} from '@lit/localize';
import {sourceLocale, targetLocales} from '../../locales';

import * as templates_es_ES from '../../locales/es-ES.js';

// a map to convert any redundant or incositsent locale keys
const LANGS = {
    'en-US': 'en'
}

export const localizedTemplates = new Map([['es-ES', templates_es_ES]]);

export const { getLocale, setLocale: _setLocale } = configureLocalization({ 
    sourceLocale,
    targetLocales,
    loadLocale: async (locale) => localizedTemplates.get(locale),
});

export const setLocale = (lang) => {
    const l = LANGS[lang] || lang
    return _setLocale(l)
}

export function getLang() {
    const lang = document.documentElement.getAttribute('lang')
    return lang || 'en'
}