import { NAMESPACE } from "../../../constants"
import { LitElement, css, html } from 'lit';
import { KeyCodes } from "../../../utils/aria"
import { clamp } from "../../../utils/index"

import styles from './field.css'

function template(elem) {
    const { name, label, align, open, mode, items, activeIndex, config = {} } = elem

    const { styles: optionStyles, optionTemplate, onSelect, isSelected = () => {} } = config

    const renderOption = (option = {}, index = 0) => {
        const { label, value } = option

        const action = (e) => {
            onSelect && onSelect(name, option, e)
            //elem.optionClicked(name, option);             
        }

        const selected = isSelected ? isSelected(option, index) : false
        
        return html`
            <div class="option ${selected ? ' selected' : ''} ${activeIndex === index ? "focus" : '' }" 
                id="option-elem-${index}"    
                value="${value}"                
                role="option"
                part="option"
                
                aria-selected="${selected ? 'true' : 'false'}"
                tabindex="-1" 
                @click=${action}
            >
                ${ optionTemplate ? optionTemplate(option, index) : html`
                    <span id="option-label" class="option-text">${typeof label === 'function' ? label() : label}</span>
                `}
                
            </div>
        `
    }

    return html`
        ${optionStyles? html`<style>${optionStyles()}</style>`: '' }
        <div class="field field-${name} ${align} ${open ? 'open' : ''} ${mode}">
            <button type="button" 
                    id="field_button"
                    aria-haspopup="listbox" 
                    aria-labelledby="field_label field_button" 
                    aria-expanded="${open ? "true" : "false"}"

                    @click=${elem.toggleMenu}
                    @keydown=${elem.onKeyPress}
                >
                    <span id="field_label">${label}</span> 
                    <oportun-icon name="arrow-down"></oportun-icon>
            </button>            
            <div class="content"
                part="menu"
                role="listbox" 
                aria-activedescendant="option-elem-${activeIndex}"
                aria-hidden="${open ? "false" : "true"}"
                tabindex="-1"

                @click=${elem.onOptionClick} 
                >
                    ${ items.map(renderOption)}
            </div>
            
        </div>
    `
}




class Field extends LitElement {

    static styles = styles

    static properties = {
        name: '',
        label: '',
        items: [],
        align: 'left',
        mode: 'menu',
        open: false,
        delay: 100,
        duration: 300,
        config: {},
        activeIndex: ''
    };
      // Define scoped styles right with your component, in plain CSS

      /**
       * toggleMenu
       * Event handler for opening and closing the menu
       * @param {PointerEvent} e: The click event 
       */
      toggleMenu(e) {

        if (this.mode === 'list' ) {
            return
        }

        this.open = !this.open
        this.activeIndex = undefined // reset active item

        const close = () => {
            document.body.removeEventListener('click', closeMenu, { capture: true })
            this.open = false
        }

        const closeMenu = (e) => {
            
            const path = [...e.composedPath()]
            let target = path.shift()
            
            // the event was triggered from a child of this node
            // stopPropagation to prevent closing the menu
            // TODO: this should be a configurable option
            if (this.shadowRoot.contains(target)) {
                //e.stopPropagation()
            }

            // check if the event was triggered from a child of the menu
            // if so, then don't close the menu
            // once the event is handled, call this.closeMenu to close the menu
            let par = path.shift()
            while(par && par !== document.body) {
                if (this.shadowRoot.contains(par)) {
                    return
                }
                par = path.shift()
            }

            // click originated outside the field
            close()
        }

        if (this.open) {    
            document.body.addEventListener('click', closeMenu, { capture: true })  
            this.closeMenu = close                 
        }
      }


      /**
       * onOptionClick
       * Event handler for an option when clicked
       * @param {PointerEvent} e: The pointerEvent  
       */
      onOptionClick(e) {
        e.stopPropagation()
        // trigger change event
        // this happens before the menu is closed
        this.triggerEvent('change', {
            detail: { selected: e.target }
        })

        this.hideMenu()
      }

      hideMenu() {
        const { delay, duration } = this
        const content = this.shadowRoot.querySelector('.content')

        if (this.mode === 'menu') {
            // fade the menu out and remove from dom
            const anim = content.animate([{ opacity: 0 }], { delay, duration })
            anim.finished.then(() => {
                this.closeMenu && this.closeMenu()
            })
        }
        
        
      }


      /**
       * triggerEvent
       * Triggers a custom event on this node
       * @param {string} name of the event. eg: change 
       * @param {object} options for the event, Use detail prop for event.detail 
       */
      triggerEvent(name, options = {}) {
        const event = new CustomEvent(name, { bubbles: true, composed: true, ...options });
        this.dispatchEvent(event);
      }


      connectedCallback() {
        super.connectedCallback()
        this.addEventListener('focus', (e) => {
            e.stopPropagation()
            e.preventDefault()
            this.shadowRoot.querySelector('button').focus()
        }, { capture: true })
      }

      onKeyPress(e) {

        const key = e.which || e.keyCode;

        const { name, config, items, activeIndex = -1 } = this
        const { onSelect } = config

        const options = this.shadowRoot.querySelectorAll('.option')

        const setFocus = (key) => {
            let activeIdx = activeIndex + (key === KeyCodes.UP ? -1 : 1)
            if (activeIdx < 0) activeIdx = items.length - 1
            if (activeIdx === items.length) activeIdx = 0 
            this.activeIndex = activeIdx
        }

        function cancelEvent() {
            e.preventDefault();
            e.stopPropagation()
        }

        const setSelected = (key, hide = true) => {

            if (!this.open || activeIndex < 0) { 
                return
            }

            const option = this.items[activeIndex]
            if (option) {
                cancelEvent()
                onSelect && onSelect(name, option)

                if (hide)
                    this.hideMenu()

                // trigger change event
                // this happens before the menu is closed
                this.triggerEvent('change', {
                    detail: { selected: e.target }
                })

            }
        }

        switch (key) {
            case KeyCodes.UP:
            case KeyCodes.DOWN:
                cancelEvent()
                setFocus(key)
              break;
            case KeyCodes.RETURN:
                setSelected(e)
            break;
            case KeyCodes.SPACE:
                setSelected(e, false)
            break;
            case KeyCodes.ESC:
                this.hideMenu()
            break;
          }
      }
    
      render() {
        return template(this)
      }




}



customElements.define(`${NAMESPACE}-filter-field`, Field);

