import {
  LitElement, css, html, svg,
} from 'lit';
import { msg, str } from '@lit/localize';
import { until } from 'lit/directives/until.js';
import { NAMESPACE } from '../constants';

import { KeyCodes } from '../utils/aria';

import './context';

function define(name, klass) {
  customElements.define(`${NAMESPACE}-${name}`, klass);
}

const styles = css``;

function template(elem) {
  return html` <div>default template</div> `;
}

export default class NorthStarElement extends LitElement {
  static styles = styles;

  __setupAnalytics() {
    // will only pick elements rendered by the component. excludes slotted elements
    const elems = this.$$('a, button');
    const fields = this.$$('input, select, textarea');
    // console.log('ELEMS', elems, this);

    const addEvents = (elem, eventName) => {
      const tealAttr = elem.getAttribute('data-event_tracking');

      if (!tealAttr) {
        console.warn('Tealium attribute missing for', elem, ' in ', this);
      }

      const handler = (e) => {
        this.sendEvents(e, {
          event: eventName,
          ...elem.dataset,
        });
      };

      elem.addEventListener(eventName, handler, { passive: true });

      return () => {
        elem.removeEventListener(eventName, handler, { passive: true });
      };
    };

    const clickHandlers = [...elems].map((el) => addEvents(el, 'pointerdown'));
    const changeHandlers = [...fields].map((el) => addEvents(el, 'change'));

    return () => {
      [...clickHandlers, ...changeHandlers].forEach((unsubscribe) => unsubscribe());
    };
  }

  setupAnalytics_2() {
    // will only pick elements rendered by the component. excludes slotted elements
    const elems = this.$$('a, button');
    const fields = this.$$('input, select, textarea');
    // console.log('ELEMS', elems, this);

    // anything that has data-tealium attrs, which is not an input, select, textarea
    // [data-event_tracking]:not(input,select,textarea)

    const addEvents = (elem, eventName) => {
      const tealAttr = elem.getAttribute('data-event_tracking');

      console.log('tealAttr', tealAttr);

      if (!tealAttr) {
        console.warn('Tealium attribute missing for', elem, ' in ', this);
      }

      const handler = (e) => {
        console.log('analytics event handler :: ', eventName, e);
        this.sendEvents(e, {
          event: eventName,
          ...elem.dataset,
        });
      };

      elem.addEventListener(eventName, handler, { passive: true });

      return () => {
        elem.removeEventListener(eventName, handler, { passive: true });
      };
    };

    const clickHandlers = [...elems].map((el) => addEvents(el, 'pointerdown'));
    const changeHandlers = [...fields].map((el) => addEvents(el, 'change'));

    return () => {
      [...clickHandlers, ...changeHandlers].forEach((unsubscribe) => unsubscribe());
    };
  }

  setupAnalytics() {
    // console.log('setup analytics', this.renderRoot);

    const addEvents = (elem, eventName) => {
      const handler = (e) => {
        const closest = e.target.closest('[data-event_tracking]');

        const { nodeName } = e.target;
        if (nodeName.toUpperCase().startsWith('OPORTUN')) {
          e.stopPropagation();
          return;
        }

        if (closest) {
          e.stopPropagation();

          this.sendEvents(e, {
            event: eventName,
            ...closest.dataset,
          });
        } else {
          this.sendEvents(e, {
            event: eventName,
            missingAttributes: true,
          });
        }
      };

      const passive = false;

      elem.addEventListener(eventName, handler, { passive });

      return () => {
        elem.removeEventListener(eventName, handler, { passive });
      };
    };

    const fields = this.$$('input, select, textarea');
    const unsubscribeChangeHandlers = [...fields].map((el) => addEvents(el, 'change'));

    const unsubscribeRootHandler = addEvents(this.renderRoot, 'mousedown');

    /*
    this.renderRoot.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    */

    return () => {
      [unsubscribeRootHandler, ...unsubscribeChangeHandlers].forEach((unsubscribe) => unsubscribe());
    };
  }

  sendEvents(e, data) {
    const { target } = e;
    // this.triggerEvent('change', { target, originalEvent: e, data });
    this.sendAnalyticsEvent({ target, originalEvent: e, data: { ...data, ...target.dataset } });
  }

  connectedCallback() {
    super.connectedCallback();

    setTimeout(() => {
      this.__destroy = this.attachedCallback();
      this.__destroy_analytics_events = this.setupAnalytics();
    }, 0);
  }

  disconnectedCallback() {
    if (this.__destroy) {
      if (typeof this.__destroy === 'function') {
        this.__destroy();
      }
    }
    if (this.__destroy_analytics_events) {
      this.__destroy_analytics_events();
    }
  }

  triggerEvent(name, detail, options = { bubbles: true, composed: true }) {
    const event = new CustomEvent(name, { detail, ...options });
    this.dispatchEvent(event);
  }

  async sendAnalyticsEvent(detail) {
    await this.updateComplete;

    this.triggerEvent('analyticsevent', detail);
  }

  attachedCallback() {}

  getSlottedChildren(name = '') {
    const query = name ? `slot[name="${name}"]` : 'slot';
    const slot = this.shadowRoot.querySelector(query);
    return slot.assignedElements({ flatten: true });
  }

  resizeObserver(fn, elem = this) {
    const ro = new ResizeObserver((entries) => {
      const entry = entries[0];
      const { contentRect } = entry;
      fn(contentRect);
    });
    ro.observe(elem);

    return {
      observer: ro,
      cancel() {
        ro.unobserve(elem);
      },
    };
  }

  render() {
    const tmpl = this.constructor.template || template;
    return tmpl(this);
  }

  $(selector) {
    return this.renderRoot.querySelector(selector);
  }

  $$(selector) {
    return this.renderRoot.querySelectorAll(selector);
  }
}

export {
  until, css, html, svg, msg, str, NorthStarElement, KeyCodes, NAMESPACE, define,
};
