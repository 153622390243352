import { styleMap } from 'lit/directives/style-map.js';
import { msg, str } from '@lit/localize';
import { NorthStarElement, html, define } from '../../northstar';

import styles from './adblock.css';

function template(elem) {
  const {
    locations, config, selectedLocation, params,
  } = elem;

  const utm_term = params.get('utm_term');

  function renderReferral() {
    const cities = config.city_list_3 || [];

    const hasState = cities.includes(selectedLocation);

    if (!hasState) {
      return renderNone();
    }

    return html`<div class="referral-info">
      <div class="ref">
        <span class="title-label">
          <slot name="referral_label"></slot>
        </span>
        <span class="bold" id="titleLocation">${utm_term}</span>
      </div>
      <div class="apply-button">
        <slot name="apply_button"></slot>
      </div>
    </div>`;
  }

  function renderNone() {
    return html`
      <div class="no-ref">
        <slot name="no_service"></slot>
      </div>
    `;
  }

  return html`
    <div class="content">
      <div class="location-selector">
        <label for="locationSelect"><slot name="field_label"></slot></label>
        <div class="field">
          <select id="locationSelect" @change=${elem.onLocationChange}>
            ${locations.map(
    (loc, i) => html`<option
                value="${loc.value}"
                ?selected=${loc.value === selectedLocation}
              >
                ${loc.label}
              </option>`,
  )}
          </select>
        </div>
      </div>
      <div>${utm_term ? renderReferral() : ''}</div>
    </div>
  `;
}

export default class Adblock extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    selectedLocation: { type: String },
  };

  constructor() {
    super();
    this._config = (window.adlanding && window.adlanding.config) || {};

    const url = new URL(location.href);
    this.params = url.searchParams;

    const selected = this._config.default_location;

    const loc = this.locations.find((l) => l.label === selected);

    this.selectedLocation = (loc && loc.value) || '';
  }

  get locations() {
    return this.config.locations || [];
  }

  get config() {
    return this._config || {};
  }

  onLocationChange(e) {
    const { target } = e;
    this.selectedLocation = target.value;
  }

  attachedCallback() {}
}

define('adblock', Adblock);
