import { KeyCodes } from '../../../utils/aria';

import { NorthStarElement, html, define } from '../../../northstar';
import { setLocale, getLang } from '../../../utils/locale';

import styles from './email-subscribe.css';

function template(elem) {
  return html`
    <div class="container gfooter__news">
      <slot name="intro"></slot>
      <form action="">
        <div class="input-group">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Your email"
            required
            data-event_tracking_label="Email"
            data-event_tracking_section="Email Capture - Footer"
            data-event_tracking="field complete"
            @change=${elem.handleInputChange}
          />
          <label for="email">Email</label>
        </div>
        <button
          data-event_tracking_label="Sign Up"
          data-event_tracking_section="Email Capture - Footer"
          data-event_tracking="button click"
          type="button"
          id="email-signup"
          class="button btn"
          @mousedown=${elem.submitSignup} 
        >
          <slot name="button">Sign up</slot>
        </button>
      </form>
    </div>
  `;
}

export default class EmailSubscribeForm extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {};

  async attachedCallback() {}

  submitSignup(e) {
    
    this.sendAnalyticsEvent({
      target: e.currentTarget,
      originalEvent: e,
      data: {
        ...e.currentTarget.dataset,
      },
    });
  }

  handleInputChange(e){
    this.sendAnalyticsEvent({
      target: e.currentTarget,
      originalEvent: e,
      data: {
        ...e.currentTarget.dataset,
      },
    });
  }
}

define('email-subscribe', EmailSubscribeForm);
