import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';

import styles from './item.css';

import './item';

function template(elem) {
  const { collapsed, collapsible, icon = { open: 'positive', close: 'negative' } } = elem;
  const isCollapsed = collapsible && collapsed;

  return html`
    <div class="item  ${collapsed ? '' : 'expanded'}">
      <span class="title" @click=${elem.toggleCollapse}>
        <slot name="title"></slot>
        ${collapsible
    ? html`<oportun-icon name="${collapsed ? icon.open : icon.close}"></oportun-icon>`
    : ''}
      </span>
      ${!collapsible
        ? html`
        <div class="content">
          <slot></slot>
        </div>`
        : html` <div class="content" style=" max-height:${ collapsed ? '0px' : elem.renderRoot.querySelector('.content').scrollHeight + "px" }">
        <slot></slot>
        </div>`
        }
    </div>
  `; 
}

export default class CollapsibleItem extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    collapsible: { type: Boolean, default: true },
    collapsed: { type: Boolean, default: true },
    icon: { type: Object, default: {} },
  };

  constructor() {
    super();
    this.collapsible = true;
    this.collapsed = true;
  }

  toggleCollapse(e) {
    e.preventDefault();

    if (!this.collapsible) {
      return;
    }

    this.collapsed = !this.collapsed;
    this.triggerEvent('toggle', { collapsed: this.collapsed });

    const event = this.collapsed ? 'collapse' : 'expand';
    this.triggerEvent(event);

    this.sendAnalyticsEvent({
      target: e.currentTarget,
      originalEvent: e,
      data: {
        collapsed: this.collapsed,
        event: 'Link click',
        label: 'Toggle item',
        section: 'Accordion',
      },
    });
  }

  async attachedCallback() {}
}

define('accordion-item', CollapsibleItem);
