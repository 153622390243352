import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';

import { sleep } from '../../utils';

import logoColor from '../../../assets/brand/logo_color.svg';
import outreachColor from '../../../assets/brand/Outreach_Color.svg';

import styles from './qrcode.css';

function template(elem) {
  const {
    status, isLoading, errors, urls,
  } = elem;

  function renderLoading() {
    if (status === 'loading') {
      return html` <div class="loading">
        <oportun-loading-spinner></oportun-loading-spinner>
      </div>`;
    }
    return '';
  }

  function renderErrors() {
    if (status === 'error') {
      return html` <div class="error">
        ${errors.map((err) => html`<div>${err.message}</div>`)}
      </div>`;
    }
    return '';
  }

  function renderCode() {
    if (status === 'complete') {
      return html`<div class="container">
        ${urls.map(
    (url) => html`<div class="qr">
            <oportun-qrcode
              .url=${url.short_url}
              .config=${url.config}
              width="400"
              height="400"
            ></oportun-qrcode>
          </div>`,
  )}
      </div>`;
    }
    return '';
  }

  return html` <div class="outreach">${renderLoading()} ${renderErrors()} ${renderCode()}</div> `;
}

export default class OutreachCode extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    errors: { type: Array },
    urls: { type: Array },
    status: { type: String },
  };

  constructor() {
    super();
    this.status = '';
  }

  getBaseUrl(lang, state) {
    let landing_page_en = 'https://oportun.com/info/retail-outreach/';
    let landing_page_es = 'https://oportun.com/es/info/retail-outreach/';

    const spl_states = ['CA'];

    if (spl_states.includes(state)) {
      landing_page_en = 'https://oportun.com/info/retail-outreach-spl/';
      landing_page_es = 'https://oportun.com/es/info/retail-outreach-spl/';
    }

    if (lang === 'ES') {
      return landing_page_es;
    }

    return landing_page_en;
  }

  generateToken(data) {
    return `o${btoa(JSON.stringify(data)).replace(/=/g, '')}o1`;
  }

  parseUrl() {
    const params = new URLSearchParams(window.location.search);
    const agent = {};

    for (const [key, value] of params) {
      agent[key] = value;
    }
    const { inputStoreId: storeId, state = 'CA', inputAgent = '' } = agent;

    const [agentId, firstName, lastName] = inputAgent.split(',');

    const data = {
      agent: {
        storeId,
        agentId,
        firstName,
        lastName,
      },
      state,
    };

    return data;
  }

  generateUrl(agentInfo) {
    const lng = getLang();
    const lang = lng.split('-')[0].toUpperCase();

    const { agent, state } = agentInfo;
    const token = this.generateToken(agent);

    const baseUrl = this.getBaseUrl(lang, agent.state);

    const outreachUrl = new URL(baseUrl);

    const qs = {
      utm_medium: 'qr',
      utm_source: 'store',
      utm_campaign: 'qr_outreach',
      outreachToken: token,
      state,
    };

    Object.entries(qs).forEach(([key, value]) => {
      outreachUrl.searchParams.append(key, value);
    });

    const url = outreachUrl.toString();

    const urls = {
      [url]: {
        short_url: '',
        lang,
      },
    };

    return urls;
  }

  // // const landing_page_params = `?utm_source=store&utm_medium=qr&utm_campaign=qr_outreach&outreachToken=${token}&state=${state}`;
  // https://oportun.com/info/ipad-retail-outreach-spl/?inputStoreId=32&inputAgent=24%2CErica+%2CGomez&state=CA
  // https://oportun.com/info/retail-outreach-spl/?utm_source=store&utm_medium=qr&utm_campaign=qr_outreach&outreachToken=oeyJzdG9yZUlkIjoiMzIiLCJhZ2VudElkIjoiMjQiLCJmaXJzdE5hbWUiOiJFcmljYSAiLCJsYXN0TmFtZSI6IkdvbWV6In0o1&state=CA

  async getUrl() {
    const agentInfo = this.parseUrl();
    const urls = this.generateUrl(agentInfo);

    const data = { urls };
    // console.log('Shortening ', data);

    try {
      const resp = await fetch('/wp-json/ocw/shorten-urls', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const content = await resp.json();
      // console.log('content', content, content.errors);

      if (content.errors) {
        const error = new Error('Error fetching qrcodes');
        error.errors = content.errors.map((err) => {
          err;
        });
        throw error;
      }

      function titleCase(str) {
        return str
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }

      const {
        firstName, lastName = '', storeId, agentId,
      } = agentInfo.agent;
      const urls = (content.urls || []).map((urlInfo) => ({
        ...urlInfo,
        config: {
          footer: `${titleCase(`${firstName} ${lastName.charAt(0)}`)} - ${storeId} - ${
            urlInfo.lang
          }`,
          titleImage: outreachColor,
          titleLogoName: 'Outreach',
        },
      }));

      this.urls = urls;

      this.qrconfig = {
        footer: '',
      };

      this.status = 'complete';
    } catch (e) {
      this.urls = [];
      console.error(e);

      this.errors = e.errors ? e.errors : [e];
      this.status = 'error';
    }
  }

  async attachedCallback() {
    this.status = 'loading';
    this.errors = null;

    // await sleep(2000);

    await this.getUrl();
  }
}

define('outreach-code', OutreachCode);
