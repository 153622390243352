import {
  NorthStarElement, css, html, define,
} from '../../northstar';

import Icons from '../../../assets/brand/icons.svg';
import Graphics from '../../../assets/brand/graphics.svg';

const styles = css`
  :host {
    display: inline-block;
    width: var(--icon-width, 30px);
    height: var(--icon-height, var(--icon-width, 30px));
    box-sizing: border-box;
    padding: var(--icon-padding, 0);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: var(--icon-fill, none);
    stroke: var(--icon-stroke, black);
    stroke-width: var(--icon-stroke-width, 1.5);
  }
`;

export class Icon extends NorthStarElement {
  static styles = styles;

  static properties = {
    name: { type: String },
    src: { type: String },
    width: { type: String },
  };

  constructor() {
    super();
    this.src = Icons;
    this.viewBox = '0 0 24 24';
    this.version = '24';
  }

  render() {
    const {
      width, src, viewBox, name, version,
    } = this;

    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}">
        <use href="${src}?${version}#${name}" />
      </svg>
      <slot></slot>
    `;
  }

  attachedCallback() {
    if (this.width) {
      this.style.setProperty('--icon-width', this.width);
    }
  }
}

define('icon', Icon);
