
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h2a093d517d94edd4': html`<em>reseñas de</em> 1 estrella `,
'ha02853bd9d715922': html`<em>reseñas de </em>${undefined} estrellas y más `,
'hb94ceff2d6bea555': html`<em>reseñas de </em> ${undefined} estrella `,
's02bf5241683ddb90': `Bueno (660-699)`,
's05e89547f0f22653': str`Aunque no puedes aplicar en persona en ${0}, puedes aplicar en línea.`,
's073a3b216acd9f5b': str`Encuentra abajo la lista de nuestras sucursales de Oportun. No existen sucursales DolEx en ${0}.`,
's089297421a0eedd6': `reseñas`,
's08b05407b5565ca4': `or`,
's09c798922b22d4fc': `Almacenar información`,
's118d4d5705dc3af9': `Monto financiado`,
's11f0d4805e155fe1': `¿Dónde vives?`,
's141b773d7fd6fa29': `Usa el título de tu auto para tener más oportunidades de un préstamo mayor`,
's1d108f42f6ffff84': str`No existen sucursales de Oportun en ${0}. Abajo enlistamos las sucursales DolEx donde están disponibles los servicios de Oportun.`,
's1dd42e81fc876df9': `/es/personal-loans/`,
's22713f64c729416e': `y más`,
's28b1593b5cf2372d': `préstamo personal`,
's2ba0e9e7ef33029c': `También puede solicitar un`,
's2d550ad2d1727c22': `Tarjetas de crédito`,
's2e2dd419ed27c52b': `Paga `,
's2eb13f7956dbcbc8': `No, no tengo auto`,
's2f74875ee0868d8f': `Localidades`,
's307795b666ff5a25': `Necesita mejorar (debajo 580)`,
's33f18e24c0f02137': `Dábado:`,
's35d0183d3e7956bb': `Buscar por dirección, código postal, ciudad o estado`,
's3606b9d856cdbafe': `Ordenar por`,
's39211f8cdcd58d9b': `Direcciones`,
's39fc74b0ff29278d': `Precisar reseñas`,
's3d5e13a41be7cbfa': `Aceptable (580-659)`,
's43c0315623d4fd77': `Puntaje desconocido / No puntaje`,
's48305c59f3b0b6c9': `Sucursales Dolex`,
's58058df6d23456e8': `Aplican tarifas estándar de datos y mensajería por llamadas, mensajes de texto o descargas.`,
's5d929ff1619ac0c9': `Buscar`,
's5e005d880b6345f0': `Cargo por pago devuelto`,
's5f18ad2335efdc47': `Muy bueno (700-749)`,
's5fbe5fa02c18b0d2': `/es/credit-cards/`,
's61b294f82a34dddc': `Numero de pagos`,
's6b5c5b615bc905d4': `Puntaje de crédito estimado`,
's6d8cf40804492c07': `Ver detalles y estado`,
's7584ded3d749c75e': `Carga más`,
's79f2525594bda923': `Sí, todavía estoy haciendo pagos`,
's7a57fd915566c247': `cada dos semanas`,
's7e1cc4ee33997748': ` Préstamos garantizados`,
's7e6e2223f1a89552': `Busque una ubicación cercana de Oportun. También puede solicitar un`,
's7f845078d7a5c0b5': `de`,
's8023cca78fa76ede': `De menor a mayor`,
's808c054e41aa8a0d': `Más recientes`,
's81e04c6775f41600': `Escanea el código QR con la cámara de tu teléfono para descargar la aplicación. Inscribirse es fácil y en menos de 5 minutos estará todo listo. En este momento, nuestras herramientas de ahorro, presupuesto e inversiones, junto con nuestra aplicación, solo están disponibles en inglés.`,
's895072a7e7669938': `Sucursales Oportun`,
's8e117fe546d1d983': `Cargo por pago retrasado`,
's9544962a7c1befa8': `Excepcional (750-850)`,
's97987596c2779ce1': `Reseñas más altas`,
's98d035772d49856e': str`Lo sentimos, pero actualmente no ofrecemos nuestros servicios en ${0}`,
's99c4b0a55c6e0e69': `en línea desde estos estados:`,
'sa054b6d2c085730b': `para ahorrar sin esfuerzo.`,
'sa0fc61989d2eaf48': `Plazo del préstamo`,
'sa62b15e722a205fc': `Aplicar una vez y obtener dos grandes opciones`,
'sa890a5e7d007e83c': `Obtén el app`,
'saa8ca31480bd3ea0': `puedes descargar nuestra app`,
'saac21e40a54fff35': `una vez al mes`,
'sae94af9791e8199a': `Préstamos personales`,
'sb0ffa04c9225566f': `Lunes-Viernes:`,
'sb22524aa70ff6204': `De mayor a menor`,
'sb3890cd1737117f3': `Ver reseñas de`,
'sb4ae9b48a23603c0': `Descarga la aplicación de Oportun`,
'sb59d68ed12d46377': `Cargando`,
'sba59c001cb559d48': `Descargarla y manejar tu préstamo personal o tarjeta de crédito es gratis. Nuestras herramientas de ahorro e inversiones son gratis por 30 dias y \$5 al mes después.`,
'sca81470958a97fc4': `Filtrar reseñas`,
'sca8b41d21a6a3c89': `Sí, soy dueño y ya no pago nada por mi auto`,
'sca92f1ee12551cc2': `Esta herramienta ofrece ejemplos de precios y de plazos de préstamos para préstamos personales estándares. Los plazos reales varían según la información de la solicitud, el perfil de crédito y la ley correspondiente del estado. Los plazos mostrados pueden cambiar sin previo aviso.`,
'sce24479e1166ee8d': `Préstamos personales`,
'sd343bac399121a1f': `Disponible en Apple y Android.`,
'sd8ecd3b9737fdc83': `Tarjetas de crédito`,
'se09f67825582d4e5': `Reseñas más bajas`,
'se16c3a252343cfe1': `Domingo:`,
'se337e3fccd7d7098': `Costo administrativo`,
'se36599241f046acf': `¿Eres dueño de tu auto?`,
'se459dbc1adc59941': ` Préstamos garantizados`,
'se5382eb457e5a877': `o una`,
'sedb5d32f53cbe086': `Comienza tu solicitud de préstamo`,
'sf36fd35510558d4d': `Monto del préstamo`,
'sf47c4b39340c356a': `Máx.`,
'sf7c5a5a63eba3242': `. O`,
'sf82ac8b4c087ab2e': `No, yo arriendo un auto`,
'sf993bb199fefbe04': `Todas`,
'sfe5857d2790cdd68': `Todos localidades`,
    };
  