import { KeyCodes } from '../../utils/aria';

import { NorthStarElement, html, define } from '../../northstar';
import { RAF } from '../../utils';

import styles from './team-card.css';

function template(elem) {
  const { state, scrolled } = elem;

  return html`
    <div class="wrapper">
      <button @click=${elem.handleClick}>
        <slot name="button"></slot>
      </button>

      <dialog>
        <div class="popup">
          <button class="close" tabindex="2"><span>Close Popup</span></button>
          <slot name="dialog"></slot>
        </div>
      </dialog>
    </div>
  `;
}

export default class TeamCard extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {};

  constructor() {
    super();
  }

  handleClick(e) {
    e.preventDefault();
    console.log('clicked');

    const dialog = this.$('dialog');
    const container = dialog.firstElementChild;
    const closeButton = dialog.querySelector('button.close');

    dialog.showModal();

    setTimeout(() => {
      dialog.classList.add('open');
    });

    // animate the container element
    container.animate(
      [
        { opacity: 0, offset: 0 },
        { opacity: 1, offset: 1 },
      ],
      { duration: 500, easing: 'cubic-bezier(.54,0,.02,.97)' },
    );

    function close() {
      dialog.classList.remove('open');
      // animate the container element, then close the dialog
      const anim = container.animate([{ opacity: 0 }], {
        duration: 400,
        easing: 'cubic-bezier(.73,-0.01,.43,.74)',
      });

      anim.finished.then(() => {
        dialog.close();
      });
    }

    function closeDialog(e) {
      e.stopPropagation();
      console.log('closing', e.target);

      if (e.target === dialog || e.target === closeButton) {
        e.stopPropagation();
        close();
      }
    }

    function onClose(e) {
      dialog.removeEventListener('click', closeDialog);
      dialog.removeEventListener('close', onClose);
      closeButton.removeEventListener('click', closeDialog);
    }

    dialog.addEventListener('close', onClose);
    dialog.addEventListener('click', closeDialog);
    closeButton.addEventListener('click', closeDialog);
  }

  async attachedCallback() {}
}

define('team-card', TeamCard);
