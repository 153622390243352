import { NorthStarElement, html, css,define } from '../../northstar';
import {range} from 'lit/directives/range.js';
import {map} from 'lit/directives/map.js';

import styles from './glossary.css';

function template(elem){
    const { selectedTab, isMobile, items } = elem;

    function renderMobile() {

        function renderMobileFilters(i){
            return html`
                <div class="filter ${(i+1) === selectedTab ? 'active' : 'inactive' }" @click=${(e) => elem.handleSelect(e, i)}>
                    <slot name="filter-${i+1}"></slot>
                </div>`;
        }
        
        function renderItem(i) {
            return html`
             <slot name="term-${selectedTab}"></slot>`
        }

        return html`
            <oportun-accordion class='glossary-filters-mobile'>
                <oportun-accordion-item icon='{"open":"arrow-down","close":"arrow-up"}' class="item stacked menu" type="stacked" mode="collapsible" collapsed="true">
                    <div class="title" slot="title">
                        <strong>By Alpha</strong>
                    </div>
                    <div class="content">
                        
                    <div class="glossary-filters-m selected-${selectedTab}">
                        ${map(range(items), renderMobileFilters)}
                    </div>
                        
                    </div>
                </oportun-accordion-item>
            </oportun-accordion>
            
            ${map(range(items), renderItem)}
        </oportun-accordion>
        
        
        `;
    }
    
    function renderDesktop() {
        return html`
        <div class="glossary-filters selected-${selectedTab}">
            ${map(range(items), (i) => {
                return html`<div class="filter ${(i+1) === selectedTab ? 'active' : 'inactive' }">
                        <slot name="filter-${i+1}" @click=${(e) => elem.handleSelect(e, i)}></slot>
                    </div>`
            })}
        </div>
        <slot name="term-${selectedTab}"></slot>
        `;
    }
    if(isMobile){
        return html `${renderMobile()}`
    }
    else{
        return html `${renderDesktop()}`
    }
    
}

export default class GlossaryOfTerms extends NorthStarElement {
    static styles = styles;
    
    static template = template;

    static properties = {
        selectedTab: { type: Number},
        isMobile: { type: Boolean },
        items: { type: Number}, 
    }

    constructor() {
        super();
        this.selectedTab = 1;
    }
  
    async attachedCallback() {
        
        const ro = new ResizeObserver(entries => {
            entries.forEach(entry => {
                if(entry.contentRect.width < 768){
                    this.isMobile = true;

                } else {
                    this.isMobile = false;
                    
                }
            })
        })
          
        ro.observe(document.body);
    }
   
    handleSelect(e, i) {
        this.selectedTab = i + 1;
        var filterAccordion = document.querySelector("oportun-glossary").shadowRoot.querySelector(".glossary-filters-mobile");
        filterAccordion.childToggle(e);
    }
}

define('glossary', GlossaryOfTerms);