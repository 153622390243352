import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import {
  NorthStarElement, html, css, define,
} from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';
import styles from './list-categories.css';

const lang = getLang();

function template(elem) {
  const { categories, isMobile, flag } = elem;

  const prefix = lang === 'es-ES' ? '/es' : '';

  function renderLink(flag, cat) {
    switch (flag) {
      case 'faq_categories':
        return html` <a class="" href="${prefix}/faq/?filter=${cat.slug} ">
          ${unsafeHTML(cat.name)}
        </a>`;

        break;
      case 'category':
        return html` <a class="" href="${prefix}/financial-education/category/${cat.slug} ">
          ${unsafeHTML(cat.name)}
        </a>`;
        break;
    }
  }

  function renderMobile() {
    return html`
      <oportun-accordion>
        <oportun-accordion-item
          icon='{"open":"arrow-down","close":"arrow-up"}'
          class="item stacked"
          type="stacked"
          mode="collapsible"
          collapsed="true"
        >
          <div class="title" slot="title">
            <strong>Categories</strong>
          </div>
          <div class="content">
            ${categories.map((cat) => html` <p class="categories">${renderLink(flag, cat)}</p> `)}
          </div>
        </oportun-accordion-item>
      </oportun-accordion>
    `;
  }

  function renderDesktop() {
    return html`<div class="list-categories">
      ${categories.length > 0 ? categories.map((cat) => html` <div class="categories">${renderLink(flag, cat)}</div> `) : `` }
    </div>`;
  }

  if (isMobile) {
    return html`${renderMobile()}`;
  }
  return html`${renderDesktop()}`;
}

export default class ListPostsCategories extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    isMobile: { type: Boolean },
  };

  constructor() {
    super();
    this.categories = [];
    this.flag = '';
  }

  async fetchData() {
    const { categories } = window;
    return categories;
  }

  async attachedCallback() {
    const response = await this.fetchData();
    const { categories, flag } = response;
    this.flag = flag;
    this.categories = categories;

    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect.width < 860) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    });

    ro.observe(document.body);
  }
}

define('list-categories', ListPostsCategories);
