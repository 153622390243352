import { msg, str } from '@lit/localize';
import { KeyCodes } from '../../utils/aria';

import { NorthStarElement, html, define } from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';
import Graphics from '../../../assets/brand/graphics.svg';
import Logo from '../../../assets/brand/logo.svg';

import { isTouch, getOS } from '../../utils/index';

import styles from './appstore-button.css';

function template(elem) {
  const {
    src, link, name, version = 13, showModal, text = '', activemobile = 'true', activedesktop = 'true' , isMobile
  } = elem;

  const lang = getLang();

  // const qrcode = lang === 'es-ES' ? 'qrcode-es' : 'qrcode';

  function renderDialogContent() {
    return html` <dialog>
      <div class="dialog-content">
        <div class="head">
          <header>
            <div class="logo">
              <img src="${Logo}" />
            </div>
            <div class="actions"></div>
          </header>
          <div class="close-btn" @click=${elem.handleClose}>
            <oportun-icon name="close" />
          </div>
        </div>

        <div class="content">
          <h1>${msg('Download the Oportun app')}</h1>
          <div class="intro">
            ${msg(
    'Scan the QR code with your phone’s camera to download the app. Signing up is simple, you’ll be ready to go in less than 5 minutes.',
  )}
          </div>
          <div class="qrcode">
            <oportun-download-qrcode width="250" height="250"></oportun-download-qrcode>
          </div>
          <div class="pricing">
            ${msg(
    'It’s free to download and manage your personal loan or credit card. Our savings and investing tools are free for 30 days and $5 per month after.',
  )}
          </div>
        </div>
        <footer>
          <div class="store-icons">
            <span>${msg('Available on Apple and Android.')}</span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <use href="${src}?${version}#appstore-logo" />
              </svg>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <use href="${src}?${version}#playstore-logo-mark" />
              </svg>
            </span>
          </div>
          <div class="disclaimer">${msg('Standard data rates may apply.')}</div>
        </footer>
      </div>
    </dialog>`;
  }

  function renderButton() {
    return html`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 100">
      <use href="${src}?${version}#${name}" />
    </svg>`;
  }

  function renderText() {
    return html`<span class="button">${text}</span>`;
  }
  function renderIfActive(){
    if((isMobile == true && activemobile == 'true') || (isMobile == false && activedesktop == 'true')){
      return html`
        <div class="wrapper">
          <a
            class="btn ${text ? 'text' : 'img'}"
            href="${link}"
            @click=${elem.handleClick}
            data-event_tracking="button click"
            data-event_tracking_label="${text || 'Get app'}"
            data-event_tracking_section="Appstore button"
          >
            ${text ? renderText() : renderButton()}
          </a>
          ${showModal ? renderDialogContent() : ''}
        </div>
      `;
    } else{
      return '';
    }
  }
  return html `${renderIfActive()}`;

}

export default class AppstoreButton extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    link: { type: String },
    src: { type: String },
    store: { type: String },
    showModal: { type: Boolean },
    text: { type: String },
    activemobile: { type: String },
    activedesktop: { type: String },
    isMobile: { type:Boolean },
  };

  constructor() {
    super();
    this.src = Graphics;
  }

  get name() {
    const { store } = this;
    switch (store) {
      case 'appstore':
        return 'appstore-button';
      case 'playstore':
        return 'playstore-button';
    }
  }

  get link() {
    const { store } = this;
    switch (store) {
      case 'appstore':
        return 'https://app.oportun.com/download';
      case 'playstore':
        return 'https://app.oportun.com/download';
    }
  }

  handleClick(e) {
    // const os = getOS();

    if (isTouch()) {
      return;
    }

    e.preventDefault();

    this.showModal = true;

    setTimeout(() => {
      const dialog = this.$('dialog');
      dialog.showModal();

      const qr = dialog.querySelector('.qrcode');

      qr.animate([{ scale: 1 }, { scale: 1.2 }, { scale: 1 }], {
        duration: 1500,
        easing: 'cubic-bezier(.46,.01,.37,.99)',
      });
    });
  }

  handleClose(e) {
    const dialog = this.$('dialog');
    if (dialog) {
      dialog.close();
      this.showModal = false;
    }
  }

  initialize() {
    // workaround to fix issue when button is inside a megamenu
    // adoptedCallback doesn't trigger because this component moves within slots and not documents
    const closeDialog = (e) => {
      this.handleClose(e);
    };
    document.addEventListener('menuhide', closeDialog);

    return () => {
      document.removeEventListener('menuhide', closeDialog);
    };
  }

  async attachedCallback() {
    const destroySelf = this.initialize();
    
    const ro = new ResizeObserver(entries => {
      entries.forEach(entry => {
          if(entry.contentRect.width < 860){
              this.isMobile = true;
          } else {
              this.isMobile = false;
              
          }
      })
    })

    ro.observe(document.body); 
    return () => {
      destroySelf && destroySelf();
    };
  }
}

define('appstore-button', AppstoreButton);
