import { NAMESPACE } from "../../../constants"
import { LitElement, css, html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { getLang } from "../../../utils/locale"
// import { when } from 'lit/directives/when.js';
import { msg, str } from '@lit/localize';

import Base from "./base.js"

export default class Phone extends Base {
    
    constructor() {
        super()

        this.pattern = "[0-9]{3} [0-9]{3} [0-9]{4}"
    }

}

customElements.define(`${NAMESPACE}-phone`, Phone);