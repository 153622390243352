import { NAMESPACE } from "../../constants"
import { LitElement, css, html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { getLang } from "../../utils/locale"
// import { when } from 'lit/directives/when.js';
import { choose } from 'lit/directives/choose.js';
import { msg, str } from '@lit/localize';

import styles from "./form.css"

import "./fields/input"
import "./fields/email"
import "./fields/phone"
import "./fields/textarea"
import "./fields/button"

function template(elem) {
    const { config, submitted, pending, thank_you_message } = elem

    // ${ repeat(items, (item) => item.reviewId, renderItem) }

    function renderField(conf) {
        const { name, label, type = 'text', span, required } = conf

        const req = required === 'yes' ? true : false

        return html`
                <div class="field span-${span}">
                <div class="field-wrapper">
                    ${choose(type, [
                        ['email', () => html`<oportun-email name=${name} class="input" @change=${elem.onChange} .config=${conf}></oportun-email>`],
                        ['phone', () => html`<oportun-phone name=${name}  class="input" @change=${elem.onChange} .config=${conf}></oportun-phone>`],
                        ['textarea', () => html`<oportun-textarea name=${name} class="input" @change=${elem.onChange} .config=${conf}></oportun-textarea>`]
                    ], () =>  html`<oportun-input name=${name} class="input" @change=${elem.onChange} .config=${conf}></oportun-input>`)}
                </div>                
            </div>
            `
    }

    if (submitted) {
        return html`
            <div class="thank_you_message">${thank_you_message}</div>
        `
    }


    return html`
        <form class="form ${pending ? 'pending' : ''}">
            <div class="fields">
                ${config.fields.map(renderField)}
                <div class="field">
                    <oportun-button text="Submit" type="submit" @click="${elem.onSubmit}"></oportun-button> 
                </div>  
            </div>
            
                   
        </form>
    `;

}



export default class Form extends LitElement {
    static styles = styles

    static properties = {
        pending: false,
        submitted: false,
        error: ""
    };


    constructor() {
        super();
        this._conf = window.formConfig
    }
    get config() {
        return this._conf
    }

    get thank_you_message() {
        return this.config.thank_you_message
    }

    onSubmit(e) {
        if (this.pending) {
            return
        }
        
        const valid = this.isValid()
        if (!valid) {
            return this.checkValidity()
        }

        this.error = ""
        this.pending = true

        //const handler = 'http://localhost:5001/gmb-reviews-37ac4/us-central1/sendMail'
        const handler = "https://us-central1-gmb-reviews-37ac4.cloudfunctions.net/sendMail"

        async function send(data) {

            const response = await fetch(handler, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            })

            const json = await response.json()
            return json
        }

        const { config } = this

        const data = this.serialize()
        
        send({ config, data }).then((response) => {
            this.pending = true
            if (response.status === 'success') {
                this.submitted = true;
            }
            if (response.error) {
                this.error = response.error;
            }
        })

    }

    serialize() {
        const inputs = this.shadowRoot.querySelectorAll('.input')
        const data = {}
        for (let input of inputs) {
            data[input.name] = input.value
        }
        return data
    }

    isValid() {
        const inputs = this.shadowRoot.querySelectorAll('.input')
        let valid = true
        for (let input of inputs) {
            if (!input.isValid()) {
                valid = false;
                break;
            }
        }
        return valid
    }

    checkValidity() {
        const inputs = this.shadowRoot.querySelectorAll('.input')
        for (let input of inputs) {
            input.checkValidity()
        }        
    }

    onChange(e) {
        const { target } = e

        target.parentNode.parentNode.classList.remove('filled')
        if (target.value) {
            target.parentNode.parentNode.classList.add('filled')
        }
        
    }

    render() {
        return template(this)
    }

}

customElements.define(`${NAMESPACE}-form`, Form);
