import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';

import logoColor from '../../../assets/brand/logo_color.svg';
// import outreachColor from '../../../assets/brand/Outreach_Color.svg';

import styles from './qrcode.css';

function template(elem) {
  const { isLoading } = elem;

  function showLoading() {
    return html` <div class="loading">
      <oportun-loader></oportun-loader>
    </div>`;
  }

  return html`
    <div class="qrcode">
      ${isLoading ? showLoading() : ''}
      <div class="container">
        <canvas></canvas>
      </div>
    </div>
  `;
}

export default class QRCode extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    url: { type: String },
    config: { type: Object },
    title: { type: String },
    width: { type: Number },
    height: { type: Number },
    header: { type: Boolean },
    footer: { type: Boolean },
    isLoading: { type: Boolean },
  };

  constructor() {
    super();
    this.isLoading = false;
  }

  async draw() {
    const { url } = this;

    if (!url) {
      return;
    }

    const config = {
      footerColor: '#000000',
      footerFont: 'normal normal normal 25px TT Commons Classic',
      footerBackgroundColor: '#fff',
      footerHeight: 20,
      headerHeight: 40,
      padding: 40,
      spacing: 20,
      ...(this.config || {}),
    };

    const container = this.$('.container');
    const canvas = this.$('canvas');

    const qr = await this.generate(canvas, {
      config,
      data: url,
      image: logoColor,
      width: 400,
      height: 400,
    });

    /*
    qr.update({
      data: url,
    });
    */
  }

  async importModules() {
    const module = await import('./generator.js');
    return module.default;
  }

  async attachedCallback() {
    this.isLoading = true;

    this.generate = await this.importModules();
    await this.draw();

    this.isLoading = false;
  }
}

define('qrcode', QRCode);
