import { KeyCodes } from '../../../utils/aria';

import { NorthStarElement, html, define } from '../../../northstar';
import { RAF, isTouch, getOS } from '../../../utils';

import styles from './apply.css';

function template(elem) {
  const { state, scrolled } = elem;

  function renderSlot() {
    return html`<slot></slot>`;
  }

  return html`
    <span
      data-event_tracking="toggle click"
      data-event_tracking_label="Apply Now"
      data-event_tracking_section="Header"
      class="btn btn--small btn--v2 apply-now-trigger"
    >
      ${renderSlot()}
    </span>
  `;
}

export default class ApplyButton extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    url: { type: String },
    os: { type: String },
  };

  constructor() {
    super();
  }

  handleClick(e) {}

  async attachedCallback() {
    this.os = getOS();
  }
}

define('apply-button', ApplyButton);
