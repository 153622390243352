import { msg, str } from '@lit/localize';

import {
  NorthStarElement, css, html, define,
} from '../../northstar';

const styles = css`
  :host {
    display: inline;
  }
`;

function format(str) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(parseInt(str));
}

const LOAN_AMOUNTS = {
  AZ: {
    upl: 8000,
    spl: 18500,
  },
  CA: {
    upl: 8000,
    spl: 18500,
  },
  FL: {
    upl: 6000,
    spl: 18500,
  },
  ID: {
    upl: 8000,
    spl: null,
  },
  IL: {
    upl: 8000,
    spl: null,
  },
  MO: {
    upl: 8000,
    spl: null,
  },
  NJ: {
    upl: 8000,
    spl: 18500,
  },
  NM: {
    upl: 8000,
    spl: null,
  },
  TX: {
    upl: 8000,
    spl: null,
  },
  UT: {
    upl: 8000,
    spl: null,
  },
  WI: {
    upl: 8000,
    spl: null,
  },
  NV: {
    upl: 8000,
    spl: null,
  },
};

function template(elem) {
  const { key, data, isSPL } = elem;
  const value = data[key] || '';

  return html` <span class="info"> ${value || html`<slot></slot>`} </span> `;
}

export default class OutreachView extends NorthStarElement {
  static styles = styles;

  static template = template;

  constructor() {
    super();

    try {
      const url = new URL(window.location.href);

      const token = url.searchParams.get('outreachToken').replace(/^o/, '').replace(/o1$/, '');

      const outreachToken = decodeURIComponent(token);
      this.data = JSON.parse(atob(outreachToken));

      const isSPL = url.pathname.match('outreach-spl');
      this.isSPL = isSPL;

      const state = url.searchParams.get('state');

      if (state) {
        this.data.state = state;
        const loan_amounts = state && LOAN_AMOUNTS[state];
        const splStateAmt = loan_amounts && loan_amounts.spl;
        const uplStateAmt = loan_amounts && loan_amounts.upl;

        if (isSPL && splStateAmt) {
          if (splStateAmt) {
            this.data.maxLoanAmount = format(splStateAmt);
          } else {
            console.warn(`The state of ${state} does not have a SPL max loan amount configured`);
          }
        }
        if (!isSPL) {
          if (uplStateAmt) {
            this.data.maxLoanAmount = format(uplStateAmt);
          } else {
            console.warn(`The state of ${state} does not have a UPL max loan amount configured`);
          }
        }
      } else if (isSPL) {
        this.data.maxLoanAmount = format(18000);
        this.data.minLoanAmount = format(6000);
      } else {
        this.data.maxLoanAmount = format(6000);
        this.data.minLoanAmount = format(6000);
      }
    } catch (e) {
      console.error(e);
      this.data = {};
    }
  }

  static properties = {
    key: { type: String },
    isSPL: { type: Boolean },
    warning: { type: String },
  };
}

define('outreach-view', OutreachView);
