import { LitElement, css, html } from 'lit';
import { msg, str } from '@lit/localize';
import { NAMESPACE } from '../../constants';
// import { choose } from 'lit/directives/choose.js';
import { observer as resizeObserver } from '../../utils/resize';
import { setLocale, getLang } from '../../utils/locale';

import 'filters';
import 'list';

import styles from './reviews.css';

export const choose = (value, cases, defaultCase) => {
  for (const c of cases) {
    const caseValues = typeof c[0] === 'string' ? c[0].split(',') : Array.isArray(c[0]) ? c[0] : [c[0]];
    if (caseValues.includes(value)) {
      const fn = c[1];
      return fn();
    }
  }
  return defaultCase?.();
};

function template(elem) {
  const lang = getLang();
  setLocale(lang || 'en');

  const {
    store, reviews, totals, filters, limit, filtersDisplay, showFilters,
  } = elem;

  function getListSummary() {
    const { source, filter_by, sort_by } = filters;

    const src = (source || '').toLowerCase();

    return html`
      ${choose(
    sort_by,
    [
      ['highest', () => html`<span>${msg('Highest to lowest rated')}</span>`],
      ['lowest', () => html`<span>${msg('Lowest to highest rated')}</span>`],
    ],
    () => html`<span>${msg('Most recent')}</span>`,
  )}
      ${choose(
    filter_by,
    [
      [
        '+4,+3,+2,+1',
        () => html`<span
              >${msg(html`${filter_by.replace('+', '')} stars and up <em>reviews</em>`)}</span
            >`,
      ],
      ['5,4,3,2', () => html`<span>${msg(html`${filter_by} star <em>reviews</em>`)}</span>`],
      ['1', () => html`<span>${msg(html`1 star <em>reviews</em>`)}</span>`],
    ],
    () => html`${msg('reviews')}`,
  )}
      ${choose(
    src,
    [
      ['google', () => html`${msg('from')} <span>Google</span>`],
      ['trustpilot', () => html`${msg('from')} <span>Trustpilot</span>`],
    ],
    () => html``,
  )}
    `;
  }

  const summary = getListSummary();

  const onStarClick = (stars) => (e) => {
    elem.setStarFilter(stars);
  };

  return html`<div class="reviews">
    <div class="review-summaries">
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="total-reviews">
        <ul class="total-stars">
          ${Object.entries(totals)
    .sort((a, b) => b[0] - a[0])
    .map(
      ([stars, details]) => html`
                <li
                  @click=${onStarClick(stars)}
                  class="row ${filters.filter_by === `${stars}` ? 'selected' : ''}"
                >
                  <span class="count">${stars}</span>
                  <span class="star">&#9733;</span>
                  <span class="progress-bar">
                    <span class="bar" style="width:calc(${details.percent}% - 0px);"></span>
                  </span>
                  <span class="percent">${details.percent}%</span>
                </li>
              `,
    )}
        </ul>
      </div>
    </div>

    <div class="filters ${showFilters ? 'expanded' : ''}">
      ${filtersDisplay === 'list'
    ? html`<div class="title" @click=${elem.toggleFilters}>
            <span>${msg('Refine reviews')}</span>
            <oportun-icon .name=${'arrow-down'}></oportun-icon>
          </div>`
    : ''}
      ${showFilters
    ? html`<oportun-review-filters .store=${store} .mode=${filtersDisplay}>
          </oportun-review-filters>`
    : ''}
    </div>
    <div class="summary">${summary}</div>
    <oportun-reviews-list .perPage=${6} .offset=${0} .limit=${limit || 6} .data=${reviews}>
    </oportun-reviews-list>
  </div>`;
}

let store;

// url based store
function createURLStore(href) {
  if (store) {
    return store;
  }

  const url = new URL(href);
  const params = url.searchParams;

  const subscriptions = [];

  store = {
    url,
    params,
    get(name) {
      return params.get(name);
    },
    setMultiple(props) {
      return Object.entries(props).reduce((acc, [key, val]) => {
        acc[key] = params.get(key);
        params.set(key, val);
        return acc;
      }, {});
    },
    set(name, value) {
      let oldValue;
      if (typeof name === 'object') {
        oldValue = this.setMultiple(name);
      } else {
        oldValue = params.get(name);
        params.set(name, value);
      }

      subscriptions.forEach((f) => f(typeof name === 'string' ? name : null, value, oldValue));
    },
    toJSON() {
      const data = {};
      // Iterating the search parameters
      for (const [key, value] of params) {
        data[key] = value;
      }
      return data;
    },
    subscribe(fn) {
      subscriptions.push(fn);
    },
  };

  return store;
}

function filterByStars(data, starRating) {
  if (starRating === 'all') {
    return data;
  }

  const exclude = !(typeof starRating === 'string' && starRating.startsWith('+'));

  return data.filter((item) => {
    if (exclude) {
      return +item.starRating === +starRating;
    }
    return +item.starRating >= +starRating;
  });
}

function filterBySource(data, source) {
  if (source === 'all') {
    return data;
  }

  return data.filter((item) => (item.source || '').toLowerCase() == source);
}

function sortReviews(data, sort_by) {
  switch (sort_by) {
    case 'lowest':
      return data.sort((a, b) => +a.starRating - +b.starRating);
      break;
    case 'highest':
      return data.sort((a, b) => +b.starRating - +a.starRating);
      break;
    default:
      return data;
      break;
  }
}

/**
 * when summary data is provided in the response
 * @param {} data
 */
function getAveragesFromSummary(data) {
  const { summary, total } = data;

  const avgs = Object.entries(summary).reduce((acc, [star, count]) => {
    const percent = ((count / total) * 100).toFixed(1);
    return { ...acc, [star]: { count, percent } };
  }, {});

  return avgs;
}

function getAverages(data) {
  const total = data.length;

  const avgs = data.reduce((acc, item, i) => {
    const stars = +item.starRating;

    if (!acc[item.starRating]) {
      acc[item.starRating] = { count: 0, percent: 0 };
    }

    const count = acc[item.starRating].count + 1;
    const percent = ((count / total) * 100).toFixed(1);

    acc[item.starRating].count = count;
    acc[item.starRating].percent = percent;

    return acc;
  }, {});

  return avgs;
}

export default class Reviews extends LitElement {
  static styles = styles;

  static properties = {
    limit: 6,
    filtersDisplay: 'menu',
    showFilters: false,
  };

  constructor() {
    super();

    // updateWhenLocaleChanges(this)

    this.allReviews = (window.reviews && window.reviews.reviews) || [];

    this.summaryData = (window.reviews && window.reviews.summary) || null;

    this.store = createURLStore(window.location.href);

    this.store.subscribe((name, newValue, oldValue) => {
      window.history.replaceState('', '', this.store.url);
      this.onFilterChange();
    });
  }

  onFilterChange() {
    this.requestUpdate();
  }

  toggleFilters(e) {
    this.showFilters = !this.showFilters;
  }

  setStarFilter(stars) {
    this.store.set('filter_by', stars);
  }

  get filters() {
    return this.store.toJSON();
  }

  get reviews() {
    const { filters, allReviews } = this;

    let reviews = allReviews.slice(0, allReviews.length);

    const {
      source, filter_by, sort_by, exclude,
    } = filters;

    if (filter_by) {
      reviews = filterByStars(reviews, filter_by, exclude);
    }

    if (source) {
      reviews = filterBySource(reviews, source);
    }

    if (sort_by) {
      reviews = sortReviews(reviews, sort_by);
    }

    return reviews;
  }

  get totals() {
    if (this.summaryData) {
      return getAveragesFromSummary(this.summaryData);
    }
    const { allReviews } = this;
    return getAverages(allReviews);
  }

  get summary() {
    return getListSummary(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.ro = resizeObserver(this, ({ bounds, entry }) => {
      const { width } = bounds;

      if (width < 720) {
        if (this.filtersDisplay !== 'list') {
          this.filtersDisplay = 'list';
          this.showFilters = false;
        }
      } else if (this.filtersDisplay !== 'menu') {
        this.filtersDisplay = 'menu';
        this.showFilters = true;
      }
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.ro) {
      this.ro.destroy();
    }
  }

  render() {
    return template(this);
  }
}

customElements.define(`${NAMESPACE}-reviews`, Reviews);
