import { KeyCodes } from '../../utils/aria';

import { NorthStarElement, html, define } from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';

import styles from './background.css';

function template(elem) {
  const { isMobile } = elem;
  return html`
  <div class="wrapper">
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="85" cy="10" r="${isMobile ? 75 : 25}">
    </svg>
  </div>
  `;
}

export default class Background extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    isMobile: { type: Boolean },
  };

  constructor() {
    super();
    this.progress = 0;
  }

  initialize() {
    const animate = (time) => {
      window.requestAnimationFrame(animate);

      if (!this._anim) {
        const svg = this.$('svg circle');
        this._anim = svg.animate(
          [
            { translate: '0% 0' },
            { translate: '0 -100%', offset: 0.5 },
            { translate: '0 -100%', offset: 1 },
          ],
          {
            duration: 1000,
            ease: 'none',
            iterations: 1,
          },
        );
        this._anim.pause();
      }
      this._anim.currentTime = (this.progress / 100) * 1000;
    };
    window.requestAnimationFrame(animate);
  }

  async attachedCallback() {
    this.initialize();

    const onScroll = (e) => {
      const { scrollHeight, clientHeight, scrollTop } = document.documentElement;
      const progress = (scrollTop / (scrollHeight - clientHeight)) * 100;

      this.progress = progress;
    };

    const ro = this.resizeObserver(() => {
      this._anim = null;
      if (window.innerWidth <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll, { passive: true });
      ro.cancel();
      if (this._anim) {
        this._anim.cancel();
      }
    };
  }
}

define('background', Background);
