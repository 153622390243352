import { NorthStarElement, html, define, until } from "../../northstar";
import {map} from 'lit/directives/map.js';

import styles from './careers.css';

function templateCareers(elem) {
  const { loading, joblocations, departments, jobs, allDepts, response, selectedDepts, isMobile } = elem;
  
  function renderJobs() {
    if (loading) {
        return ''
    }
    
    return html`<div class="job-list">
        <h6 class="gh-column-title">Featured Jobs</h6>
        ${jobs.map((job) => {
            return html`
              <div class="gh-job">
                <div class="gh-job-title">
                  <a href="${job.ghurl}"  >${job.title} </a>
                  <oportun-icon name="arrow-right"></oportun-icon>
                </div>
                <div class="job-item_location">${Object.values(job.locations)[0]}</div>
              </div>
            `
        })}
      </div>`
  }

  function renderDepts() {
    if (loading) {
        return ''
    }

    if(isMobile){
      return html`<div class="dept-list">
        <h6 class="gh-column-title">Departments</h6>
        <select name="gh-depts" id="gh-depts" @change=${elem.onDeptChange}>
        ${allDepts.map((dept) => {
          return html`
            <option value="${dept.id}">
              ${dept.name} (${dept.jobs})
            </option>
          `
        })}
        </select>
      </div>`
    }
    else{
      return html`<div class="dept-list">
        <h6 class="gh-column-title">Departments</h6>
        ${allDepts.map((dept) => {
            return html`
              <div class="dept-name">
                <span class="${dept.id == selectedDepts ? 'active' : 'inactive' }" @click=${(e) => elem.handleSelectDept(dept,e)}>
                  ${dept.name} (${dept.jobs})
                </span>
              </div>
            `
        })}
      </div>`
    }

    
  }

  function renderGHLocations() {
    if (loading) {
        return ''
    }
    
    return html`<div class="ghlocation-list">
        <h6 class="gh-job-subtitle--locations">Select location</h6>
        <select name="gh-offices" id="gh-offices" @change="${elem.onLocationChange}">
        ${joblocations.map((location) => {
            return html`
              <option value="${location[0]}" >${location[1]}</option>
            `
        })}
        </select>
    </div>`
  }

  function renderLoading() {
    return html`<div class="loading-spinner">
      <oportun-loading-spinner style="--loader-width:64px;"></oportun-loading-spinner>
    </div>` 
  }

  function renderCareers() {
    return html`
        <div class="wrapper">
          <div class="gh-locations"> 
            ${renderGHLocations()}
          </div>
          <div class="gh-container">
            <div class="depts-results">
              ${renderDepts()}
            </div>
            <div class="job-results" id="job-results">
              ${renderJobs()}
            </div>
          </div>
        </div>` 
  }

  return html`
      <div class="careers">
          ${elem.loaded ? renderCareers() : renderLoading() }
      </div>
  `
}

class Careers extends NorthStarElement{
  
  static template = templateCareers
  static styles = styles

  static properties = {
    loaded: false,
    isMobile: { type: Boolean },
  }
  
  constructor(){
    super()
    this.allJobs = []
    this.selectedLocs = 'all'
    this.selectedDepts = 'all'
    this.allDepts = []
  }

  get jobs(){
    
    if( this.selectedLocs == 'all' && this.selectedDepts == 'all' ){
      return this.allJobs
    }

    if(this.selectedLocs == 'all' && this.selectedDepts != 'all' ){
      const jobs =  this.allJobs.filter((job) => {
        if( job.departments[0] == this.selectedDepts){
          return true
        }
        else{
          return false
        }  
      })

      return jobs
    }

    let totalJobs = 0
    const jobs =  this.allJobs.filter((job) => {
      if( Object.keys(job.locations)[0] == this.selectedLocs ){
        if( this.selectedDepts == 'all' ){
          this.allDepts.map((dept) => {
            if(dept.id == job.departments[0])
            dept.jobs++
          })
          totalJobs++
          this.allDepts[0].jobs = totalJobs
          return true;
        }
        else{
          if( job.departments[0] == this.selectedDepts){
            return true
          }
          else{
            return false
          }
        }
      }
      else{
        return false;
      }

    })
    
    return jobs 
  }

  async attachedCallback() {

    function fetchJobs() {
      const baseURL = window.location.origin+'/wp-json/ocw-greenhouse/getjobs';
      return fetch( baseURL ).then(response => { return response.json() })
    }
    
    const [ response ]  = await Promise.all([ fetchJobs()])
    const { jobs, locations , departments } = response

    this.loaded = true
    this.allJobs = jobs
    this.joblocations =  Object.entries({ 'all': 'All Locations', ...locations})
    this.departments = [{id:'all', name: 'All Departments', jobs: this.jobs.length }, ...departments]
    
    this.allDepts = JSON.parse(JSON.stringify(this.departments))
    
    this.selectedLocs = 'all'
    this.selectedDepts = 'all'
    
    const ro = new ResizeObserver(entries => {
      entries.forEach(entry => {
          if(entry.contentRect.width < 768){
              this.isMobile = true;

          } else {
              this.isMobile = false;
              
          }
      })
    })
      
    ro.observe(document.body);  
  }
  
  onLocationChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.selectedLocs = value;
    this.selectedDepts = 'all'; 
    this.allDepts.map((dept) => {
      dept.jobs = 0;
    })
    if(value == 'all'){
      this.allDepts = JSON.parse(JSON.stringify(this.departments))
    }
    this.requestUpdate();

  }

  handleSelectDept(f,e) {
    
    this.selectedDepts = f.id
    this.requestUpdate()

  }

  onDeptChange(e){
    const { target } = e
    const { name, value } = target
    this.selectedDepts = value
    this.requestUpdate()
  }
  

}
define(`careers` , Careers);

