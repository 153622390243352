import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';

import styles from './star-rating.css';

function template(elem) {
  const { count = 5, value = 5 } = elem;

  if (value === 0) {
    return '';
  }

  const stars = Array(+count)
    .fill(0)
    .map((n, i) => i + 1);

  return html`
    <span class="stars"
      >${stars.map((n) => html`<span class="${n <= +value ? 'on' : 'off'}">★</span>`)}</span
    >
  `;
}

export default class StarRating extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    count: { type: Number },
    value: { type: Number },
  };
}

define('stars', StarRating);
